import React, {useEffect, useState, Fragment} from 'react';
import {useParams} from 'react-router-dom';

import Loader from 'components/atoms/Loader';
import ProductInventory from 'components/profiles/products/ProductInventory';
import OrganizationSelector from 'components/profiles/orgs/OrganizationSelector';
import IconSVG from 'components/icons/IconSVG';
import Switch from 'components/forms/elements/Switch';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [filterOrg, setFilterOrg] = useState(null);
  const [filterActive, setFilterActive] = useState(false);
  const [filterInStock, setFilterInStock] = useState(false);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [products, setProducts] = useState(null);
  const [testValue, setTestValue] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.coreOrg && props.coreOrg !== coreOrg) {
      setCoreOrg(props.coreOrg);
    }
  }, [props.coreOrg]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(()=> {
    if(props.objExists(props.admin, `organizations`) && 
      props.admin.organizations !== organizations) {
        setOrganizations(props.admin.organizations);
      }
  }, [props.admin.organizations])
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);

  useEffect(() => {
    props.canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.admin, 'products'))) {
        let prods = [...props.admin.products];
        if(coreOrg > 1) {
          prods = prods.filter(prod => prod.organization_id === coreOrg);
        }
        if(prods !== products) {
          setProducts(prods);  
        }
    }
  }, [props.admin.products, coreOrg]);
  
  useEffect(() => {
    if(userID && token) {
      const dlLink = `https://api.aecore.app/files/xlsx.php?query=${btoa(`?type=inventory&user_id=${userID}&token=${token}&organization_id=${coreOrg ? coreOrg : 0}&product_published=false&product_inventoried=true`)}`;
      if(dlLink !== downloadLink) {
        setDownloadLink(dlLink);
      }
    }
  }, [userID, token, coreOrg])
  
  
  
  // FUNCTIONS
  function selectOrg(org) {
    if(parseInt(org) > 1 && coreOrg !== parseInt(org)) {
      setCoreOrg(parseInt(org));
      if(props.actionSelectOrg) {
        props.actionSelectOrg(parseInt(org));
      }
    }else if(coreOrg && (parseInt(org) <= 0 || !org)) {
      setCoreOrg(null);
      if(props.actionSelectOrg) {
        props.actionSelectOrg(parseInt(null));
      }
    }
  }

  return (
    <div>
      <h3 className="margin-bottom-1em">{`${organizationName ? `${organizationName} ` : ''}Product Inventory`}</h3>
      {organizations && 
        organizationID <= 1 &&
        <div>
          <h4>Filter by organization</h4>
          <OrganizationSelector {...props}
            organizations={organizations}
            action={selectOrg}
            orgID={coreOrg}
            skipCore={true}
            userAccess={userAccess}  />
        </div>
      }
      {languageCode && Array.isArray(products) ?
        <Fragment>
          <div className="margin-bottom-1em margin-top-1em">
            <div className="flex">
              <div className="column">
                <Switch label="Active" value={filterActive} action={()=>{setFilterActive(filterActive ? false : true)}} />
              </div>
              <div className="column">
                <Switch label="In Stock" value={filterInStock} action={()=>{setFilterInStock(filterInStock ? false : true)}} />
              </div>
            </div>
          </div>
          {downloadLink && products.length > 0 &&
            <div className="form-row">
              <a href={downloadLink} className="link-btn margin-right-0em">
                <IconSVG icon="download" />
                Download Inventory Report
              </a>
            </div>  
          }
          <ProductInventory {...props}
                      userID={userID}
                      token={token}
                      canEdit={canEdit}
                      isSuper={isSuper}
                      organizationID={organizationID}
                      languageCode={languageCode}
                      products={products}
                      location='admin'
                      isAdmin={true}
                      filterActive={filterActive}
                      filterInStock={filterInStock} />
        </Fragment>
        :
        <Loader />
      }
    </div>
  )
}
