const pwReq = {
  minLength: 8,
}

export function setWindowLoc(location) {
  window.location.assign(location);
}

export function isEmail(value) {
  return /\S+@\S+\.\S+/.test(value);
};

export function isPassword(value) {
  if(pwReq && value && Object.entries(value).length > 0) {
    if(pwReq.minLength && value &&
      value.length < parseInt(pwReq.minLength)) {
        return false;
    } else {
      return true;
    }
  }else{
    return false;
  }
}

export function isPhone(value) {
  value = value.replace(/\D/g,'');
  return value.length === 10;
}

export function phoneDisplay(phone) {
  phone = phone.replace(/\D/g,'');
  if(phone.length > 3 && phone.length <= 6) {
    phone = phone.substring(0, 3)+'-'+phone.substring(3, (phone.length));
  }else if(phone.length > 6 && phone.length < 10) {
    phone = phone.substring(0, 3)+'-'+phone.substring(3, 6)+'-'+phone.substring(6, phone.length);
  }else if (phone.length >= 10){
    phone = phone.substring(0, 3)+'-'+phone.substring(3, 6)+'-'+phone.substring(6, 10);
  }
  return phone;
}

export function numberDisplay(value, isDecimal = true) {
  value = value.toString().replace(/[^0-9.]/g,'');
  if(!isDecimal) {
    value = Math.ceil(parseFloat(value)).toString();
  }else{
    return value;
  }

  let valueDecimal;

  if(value.toString().match('.')) {
    valueDecimal = value.toString().split('.')[1];
    value = value.toString().split('.')[0];
  }

  let valueLength = value.length;
  let valueNumber = '';

  if(valueLength > 3) {
    // if

    while(valueLength > 3) {
      valueLength = valueLength - 3;
      valueNumber = value.substring(valueLength, valueLength + 3) + "," + valueNumber;
    }
    valueNumber = value.substring(0, valueLength) + "," + valueNumber;
    value = valueNumber.substring(0, valueNumber.length - 1);
  }

  if(valueDecimal) {
    value = value + '.' + valueDecimal;
  }
  return value;
}

export function roundDecimalFraction(value, place) {
  value = String(parseInt(value));
  if(value.length > place) {
    let roundedValue = parseInt(value.substr(0, place));
    let roundedNumber = '.';
    roundedNumber += parseInt(value.substr(place, value.length));
    roundedNumber = Math.round(parseFloat(roundedNumber));
    roundedValue = parseInt(roundedValue) + roundedNumber
    value = roundedValue;
  }
  return value;
}

export function isNumber(value) {
  if(!Number.isFinite(value)) {
    if(value.match(/[^0-9.]/)) {
      return false;
    }else{
      return true;
    }
  }else{
    return true;
  }
}

export function cleanNumber(value, isFloat = true) {
  if(value.toString() === '.' || value.toString().indexOf('.') === value.toString().length - 1) {
    return value;
  }else{
    return isFloat &&  value.toString().indexOf('.') >= 0 ? 
              parseFloat(value.toString().replaceAll(/[^0-9.]/g,'')).toFixed(
                value.toString().split('.')[1].replaceAll(/[^0-9.]/g,'').length
              ) : parseInt(value.toString().replace(/[^0-9]/g,''));
  }
}

export function numberInput(value, isDecimal = true, decimalLimit = null) {
  value = cleanNumber(value, isDecimal);
  if(value.toString().indexOf('.') < 0 && isNaN(value)) {
    value = '';
  }
  
  if(isDecimal && decimalLimit && parseInt(decimalLimit) > 0) {
    decimalLimit = parseInt(decimalLimit);
  }else{
    decimalLimit = null;
  }
  
  if(isDecimal) {
    if(value === '' || value === null) {
      return '';
    }else{
      if(value.toString().indexOf('.') >= 0) {
        let valueString = value.toString();
        
        if(valueString.indexOf('.') === 0) {
          if(valueString.length === 1) {
            return '.';
          }else{
            return `.${parseInt(valueString.substring(1, decimalLimit ? decimalLimit+1 : 100))}`;  
          }
        }else{
          if(valueString.indexOf('.') === valueString.length - 1) {
            return `${parseInt(valueString.substring(0, valueString.length))}.`;  
          }
          
          let valueNodes = valueString.split('.');
          if(valueNodes.length > 2) {
            let node2 = '';
            for(var i = 1; i < valueNodes.length; i++) {
              node2 += valueNodes[i];
            }
            valueNodes = [valueNodes[0], node2];
          }
          let splitValue = '';
          let decimalSet = false;
          valueNodes.map((vNode, index) => {
            if(!decimalSet) {
              decimalSet = true;
              splitValue += `${vNode.replace(/[^0-9.]/g,'')}.`; 
            }else{
              if(decimalLimit) {
                splitValue += vNode.replace(/[^0-9.]/g,'').substring(0, decimalLimit);  
              }else{
                splitValue += vNode.replace(/[^0-9.]/g,'');
              }
            }
            return;
          });
          return decimalLimit > 0 ?
                  parseFloat(splitValue).toFixed(decimalLimit) :
                    splitValue.toString().indexOf('.') >= 0 ?
                      parseFloat(splitValue).toFixed(splitValue.toString().split('.')[1].length) :
                      parseFloat(splitValue);
        }
      }else{
        return parseInt(value);
      }
    }
  }else{
    let wholeValue = value;
    if(typeof value === 'string') {
      parseFloat(value.replace(/[^0-9.]/g,''));  
    }
    
    if(isNaN(wholeValue)) {
      return '';
    }else{
      return Math.round(wholeValue);
    }
  }
}

export function projectNumDisplay(project_id, organization_code) {
  return organization_code+'CP'+String(project_id).padStart(6, '0');
}

export function orderNumDisplay(project_id, order_id, organization_code) {
  return organization_code+'CO'+String(project_id).padStart(6, '0')+'-'+String(order_id).padStart(6, '0');
}

// PRICING FUNCTIONS
export function getDiscount(qty, discounts) {
  let product_discount = 0;
  if(discounts) {
    const discount = discounts.filter(discount => qty >= discount.discount_quantity);
    if(discount && discount.length > 0) {
      product_discount = discount[discount.length-1].discount_percentage;
    }
  }
  return product_discount;
}

export function getDiscountFactor(discount) {
  if(discount > 0) {
    return parseFloat((100-discount)*parseFloat(.01));
  }else{
    return 1;
  }
}

export function priceCalc(qty, unitPrice, basePrice = 0, discounts = false, round = false, pageBasePrice = 0, pageDiscounts = false, productCharge = 0) {
  let discount = 1;
  if(discounts) {
    discount = getDiscountFactor(getDiscount(qty, discounts));
  }
  
  let price = qty*unitPrice*discount;
  if(price < basePrice) {
    price = basePrice;
  }
  
  let pageDiscount = 1;
  if(pageDiscounts) {
    pageDiscount = getDiscountFactor(getDiscount(qty, pageDiscounts));
  }
  
  let pagePrice = 0;
  if (pageBasePrice > 0) {
    let pagePrice = qty*pageBasePrice*pageDiscount;
    price += pagePrice;
  }
  
  if (productCharge > 0) {
    price += parseFloat(productCharge);
  }

  if(round) {
    switch(round) {
      case 'ceiling':
        price = Math.ceil(price);
        break;
      case 'round':
        price = Math.round(price);
        break;
      case 'floor':
        price = Math.floor(price);
        break;
      default:
        break;
    }
  }
  return parseFloat(price).toFixed(2);
}

export function priceDisplay(price, uLang = 'en') {
  price = parseFloat(price).toFixed(2);
  switch (uLang) {
    case 'en':
      return '$'+price.toString();
    case 'fr':
      return price.toString().replace('.', ',')+' $';
    default:
      return '$'+price.toString();
  }
}


// DATE FUNCTIONS
export function getDateNodes(date) {
  if(typeof date.match === 'function') {
    if(date.match('-')) {
      return date.split('-');
    }else if(date.match('/')) {
      return date.split('/');
    }else if(date.match('.')){
      return date.split('.');
    }else{
      return false;
    }
  }else{
    return false;
  }
}

export function dateDisplay(date, uLang = 'en', format = false, full = false) {
  if(!isDate(date)) {
    date = convertISODate(date);
  }
  if(isDate(date)) {
    let fDate = setDate(date);
    let style;
    full ? style = 'full' : style = 'short';
    const months = {en: {full: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                          short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']},
                    fr: {full: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'],
                          short: ['janv.', 'févr.', 'mars', 'avril', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.']}};

    const days = {en: {full:['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                        short: ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat']},
                  fr: {full: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
                        short: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam']}}

    const M = months[uLang][style][fDate.getMonth()];
    const m = parseInt(fDate.getMonth()+1).toString().padStart(2,0);
    const D = days[uLang][style][fDate.getDay()];
    const d = parseInt(fDate.getDate()).toString().padStart(2,0);
    const Y = fDate.getFullYear();
    const h = parseInt(fDate.getHours()).toString().padStart(2,0);
    const t = parseInt(fDate.getMinutes()).toString().padStart(2,0);
    const s = parseInt(fDate.getSeconds()).toString().padStart(2,0);

    switch (format) {
      case 'numeric':
        return date = `${Y}-${m}-${d}`;
      case 'iso':
      case 'db':
      case 'mysql':
        return date = `${Y}-${m}-${d} ${h}:${t}:${s}`;
      case 'year':
        return date = Y;
      case 'month-year':
        return date = `${M} ${Y}`;
      case 'formal':
        return date = `${D},${M} ${d}, ${Y}`;
      case 'text':
      default:
        return date = `${M} ${d}, ${Y}`;
    }
  }else{
    return '*date error*';
  }
}

export function convertISODate(date) {
  let jsDate;
  const dateTime = date.split(' ');
  if(dateTime.length === 2) {
    const dateNodes = dateTime[0].split('-');
    const timeNodes = dateTime[1].split(':');
    if(dateNodes.length === 3 && dateNodes[0].length === 4) {
      jsDate = new Date(dateNodes[0], 
                        dateNodes[1]-1, 
                        dateNodes[2], 
                        timeNodes[0] ? timeNodes[0] : 0, 
                        timeNodes[1] ? timeNodes[1] : 0, 
                        timeNodes[2] ? timeNodes[2] : 0);  
    }
    
  }
  return jsDate;
}

export function setDate(date) {
  if(isDate(date)) {
    if(typeof date.getMonth === 'function') {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    }else if(date.match(/^\d{4}[./-]\d{2}[./-]\d{2}$/)) {
      const dateNodes = getDateNodes(date);
      return new Date(parseInt(dateNodes[0]), parseInt(dateNodes[1])-1, parseInt(dateNodes[2]));
    }else if(new Date(date).getTime() > 0) {
      return new Date(date);
    }else{
      return false;
    }
  }else{
    return false;
  }
}

export function isDate(date) {
  if(date && typeof date.getMonth === 'function') {
    return true;
  }else if(date && date.match(/^\d{4}[./-]\d{2}[./-]\d{2}$/)) {
    return true;
  }else if(new Date(date).getTime() > 0){
    return true;
  }else{
    return false;
  }
}


// TIME FUNCTIONS

export function isTime(time, target) {
  switch(target) {
    case 'db':
    default:
      if(time.match(/^\d{2}[:]\d{2}[:]\d{2}$/)) {
        return true;
      }else{
        return false;
      }
  }
}

export function timeDisplay(date, includeSeconds = true, format = '12hr') {
  if(!isDate(date)) {
    date = convertISODate(date);
  }
 
  if(isDate(date)) {
    date = new Date(Date.parse(date));
    let hours = date.getHours();
    let dayPart = 'am';
    if(format === '12hr' && hours > 11) {
      if(hours > 12) {
        hours -= 12;  
      }
      dayPart = 'pm';
    }
  
    let minutes =("0" + date.getMinutes()).slice(-2);
    let seconds = date.getSeconds();
  
    return `${hours}:${minutes}${includeSeconds ? `:${seconds}` : ''} ${format === '12hr' ? dayPart : ''}`;
  }else{
    return '';
  }
}

export function datetimeDisplay(date, uLang = 'en', dFormat = false, dFull = false, tFormat = '12hr', includeSeconds = false) {
  return `${dateDisplay(date, uLang, dFormat, dFull)} - ${timeDisplay(date, includeSeconds, tFormat)}`;
}

// CONVERT MINUTES TO MILLISECONDS
// IF NO VALUE IS CREATED DEFAULT OF 10 MIN 
// IS SUPPLIED TO NOT BREAK TIMING FUNCTIONS
export function minToMS(minutes = null) {
  if(parseFloat(minutes) > 0) {
    const ms = parseFloat(minutes) * 60 * 1000;
    if(ms > 0) {
      return ms;
    }else{
      return 600000;
    }
  }else{
    return 600000;
  }
}


// FILE METHODS

export function fileExists(file) {
  let http = new window.XMLHttpRequest();

  http.open('HEAD', file, false);
  http.send();
  return http.status !== 404;
  // return true;
}

export function setFilePath(file, path) {
  if(!file || !file.includes('.') || file === undefined) {
    return null;
  }else{
    if(path[path.length-1] !== '/') {
      path += '/';
    }

    if(path.includes('D:Virtualscore.accellgraphics.ca/')) {
      path = path.replace('D:Virtualscore.accellgraphics.ca/', '');
    }else if(path.includes('/var/www/vhosts/aecore.app/httpdocs')) {
      path = path.replace('/var/www/vhosts/aecore.app/httpdocs', '');
    }

    if(!path.toLowerCase().includes('http://') &&
      !path.toLowerCase().includes('https://') &&
      path[0] !== '/') {
      path = '/'+path;
    }
    path = path.substring(path.indexOf('/'), path.length);
    return path+file;
  }
}

export function getPath(path) {
  return(path.substr(path.indexOf('/'), path.length));
}

export function displayFileSize(size, unit = null) {
  if((!unit && parseInt(size) >= 1073741824) ||
      (unit && unit.toLowerCase() === 'gb')) {
    return `${(parseInt(size)/1073741824).toFixed(2)} GB`;
  }else if((!unit && parseInt(size) >= 1048576) ||
      (unit && unit.toLowerCase() === 'mb')) {
    return `${(parseInt(size)/1048576).toFixed(2)} MB`;
  }else if((!unit && parseInt(size) >= 1000) ||
      (unit && unit.toLowerCase() === 'kb')) {
    return `${(parseInt(size)/1000).toFixed(2)} KB`;
  }else if((!unit && parseInt(size) < 1000) ||
      (unit && unit.toLowerCase() === 'b')){
    return `${parseInt(size)} B`;
  }
}

// MEDIA METHODS
export function mediaType(media_file_tag) {
  switch(media_file_tag) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'tif':
    case 'tiff':
    case 'svg':
      return 'image';
    case 'pdf':
      return 'file';
    case 'm4v':
    case 'mp4':
    case 'mov':
      return 'video';
    default:
      return 'media';
  }
}

export function getFileTag(media_file_type) {
  switch(media_file_type) {
    case 'application/pdf':
      return 'pdf';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/x-photoshop':
      return 'psd';
    case 'video/mp4':
      return 'mp4';
    case 'application/postscript':
      return 'eps';
    case 'application/zip':
      return 'zip';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case "image/gif":
      return 'gif';
    case "image/jpeg":
    case "image/pjpeg":
      return 'jpg';
    case "image/svg+xml":
      return 'xml';
    case "image/png":
      return 'png';
    case "image/bmp":
      return 'bmp';
    default:
      if(media_file_type.seach('/')) {
        return media_file_type.split('/')[0];
      }else{
        return media_file_type;
      }
  }
}

export function getAllFields(fieldsets) {
  let fields = [];
  fieldsets.map(fieldset => {
    fieldset.fields.map(field => {
      return fields.push(getField(field));
    })
    return true;
  })

  fields.map(field => {
    if(field.option_groups) {
      field.option_groups.map(optGroup => {
        optGroup.options.map(option => {
          if(option.fields) {
            option.fields.map(optField => {
              return fields.push(optField);
            })
          }
          return true;
        })
        return true;
      })
    }
    return true;
  })
  return fields;
}

export function getField(field) {
  let thisField = {
    field_name: field.field_name,
    field_type: field.field_type,
    field_label: field.field_label,
    field_required: field.field_required,
  };

  if(field.option_groups) {
    thisField.option_groups = getFieldOptions(field.option_groups);
  };
  return thisField;
}

export function getFieldOptions(option_groups) {
  let groups = []
  option_groups.map(option_group => {
    let options = [];
    option_group.options.map(option => {
      let thisOption = { option_name: option.option_name, option_value: option.option_value,}
      if(option.fieldsets) {
        thisOption.fields = getAllFields(option.fieldsets);
      }
      return options.push(thisOption);
    });
    return groups.push({group_name: option_group.group_name, options: options})
  })
  return groups;
}

export function getOptionName(option_groups, value) {
  if(Array.isArray(option_groups)) {
    let options = [];
      option_groups.map(group => {
        Array.isArray(group.options) &&
        group.options.map(option => {
          return options.push(option);
        })
        return true;
      });

      if(value && options.find(opt => opt.option_value.toString().toLowerCase() === value.toString().toLowerCase())) {
        return options.find(opt => opt.option_value.toString().toLowerCase() === value.toString().toLowerCase()).option_name;
      }else{
        return null;
      }
  }else{
    return null;
  }
}

export function codifyPlainText(text) {
  return String(text).toLowerCase().replace(/\s/g, '-').replace('&', 'and').replace(/'/g, '');
}

export function encodeHTML(text){
  text = text.replace(/(?:\r\n|\r|\n)/g, '</p><p>');
  text = text.replaceAll('<p></p>', '');
  text = `<p>${text}</p>`;
  
  return text;
}

export function htmlBreaks(text){
  if(typeof text === 'string') {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br />'); 
  }else{
    return '';
  }
}

export function encodeHTMLSpaces(text) {
  text = text.replaceAll(/ /g, '&nbsp;');
  return text;
}

export function camelCase(text) {
  text = text.toString();
  text = text.toLowerCase();
  text = text.replace('&', 'and');
  text = text.replace('-', ' ');
  const tNodes = text.split(' ');
  let cCase = '';
  tNodes.map((tNode, index) => {
    if(index > 0) {
      cCase += tNode.charAt(0).toUpperCase();
      cCase += tNode.slice(1);
    }else{
      cCase += tNode;
    }
  })
  return cCase;
}

export function checkNested(obj, level,  ...rest) {
  if (obj === undefined) return false
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true
  return checkNested(obj[level], ...rest)
}


// OJBECT FUNCTIONS:
export function objClone(obj) {
  if(Array.isArray(obj)) {
    let objArray = [];
    obj.map(ray => {
      objArray.push(objClone(ray));
      return true;
    })
    return [...objArray];
  }else if(typeof obj === 'object') {
    var nobj=Object.create(Object.getPrototypeOf(obj));
    Object.assign(nobj,obj);
    for(var key in nobj){
      if(Array.isArray(nobj[key])) {
        nobj[key] = [...objClone(nobj[key])];
      }else if(typeof nobj[key] === "object"){
        nobj[key] = objClone(nobj[key]);
      }else{
        let vobj;
        switch(typeof obj) {
          case 'boolean':
            vobj = nobj[key] === true? true : false;
            break;
          case 'string':
            vobj = (' ' + nobj[key]).slice(1);
            break;
          case 'number':
            vobj = nobj[key]*1;
            break;
          default:
            vobj = nobj[key];
            break;
        }
        nobj[key] = vobj;
      }
    }
    // nobj.clonedfrom=obj;
    return {...nobj};
  }else{
    return JSON.parse(JSON.stringify(obj));
  }
}

export function objExists(obj, location) {
  const locNodes = location.split('.');
  locNodes.map(node => {
    if(node.match(/\[[0-9]+\]/g)) {
      const aNodes = node.split(/\s*\[([0-9]+)\]\s*/g).filter(Boolean);
      aNodes.map(aNode => {
        if(aNode && obj && obj[aNode]) {
          obj = obj[aNode];
        }else{
          obj = null;
        }
      })
    }else{
      if(node && obj && obj[node]) {
        obj = obj[node];
      }else{
        obj = null;
      }
    }
  })
  return obj ? true : false;
}

export function objGetValue(obj, location) {
  const locNodes = location.split('.');
  locNodes.map(node => {
    if(node.match(/\[[0-9]+\]/g)) {
      const aNodes = node.split(/\s*\[([0-9]+)\]\s*/g).filter(Boolean);
      aNodes.map(aNode => {
        if(aNode && obj && obj[aNode]) {
          obj = obj[aNode];
        }else{
          obj = null;
        }
      })
    }else{
      if(node && obj && obj[node]) {
        obj = obj[node];
      }else{
        obj = null;
      }
    }
  })
  return obj ? obj : false;
}

export function objSetValue(obj, location, value) {
  const newObj = obj;
  const locNodes = location.split('.');
  locNodes.map((node, index) => {
    if(node.match(/\[[0-9]+\]/g)) {
      const aNodes = node.split(/\s*\[([0-9]+)\]\s*/g).filter(Boolean);
      aNodes.map((aNode, aIndex) => {
        if(aNode && obj && obj[aNode]) {
          obj = obj[aNode];
        }else{
          
            if(index+1 === locNodes.length) {
              if(obj[aNode]) {
                obj[aNode] = value;
              }else{
                if(!Array.isArray(obj)) {
                  obj = [value];
                }else{
                  obj.push(value);   
                }
              }
            }else{
              if(obj[aNode]){
                obj = obj[aNode];
              }else{
                if(aIndex === 0) {
                  obj[aNode] = [];
                  obj = obj[aNode];
                }else if(aIndex + 1 === aNodes.length) {
                  if(Array.isArray(obj)) {
                    for(let lp = 0; lp < aNode; lp++) {
                      if(!obj[lp]) {
                        obj[lp] = null;
                      }
                    }
                    obj[aNode] = {};
                    obj = obj[aNode];
                    // obj.push({});
                    // obj = obj[obj.length - 1];
                  }else{
                    obj = [{}];
                    obj = obj[0];
                  }
                }else{
                  if(Array.isArray(obj)) {
                    obj.push([]);
                    obj = obj[obj.length - 1];
                  }else{
                    obj = [[]];
                    obj = obj[0];
                  }
                }
              }
            }
        }
      })
    }else{
      if(locNodes.length === index + 1){
        if(Array.isArray(obj) && !obj[node]) {
          const arrayVal = [...obj, {[node]: value}];
          obj = [...obj, {[node]: value}];
        }else{
          obj[node] = value;  
        }
      }else if(node && obj && obj[node]) {
        obj = obj[node];
      }else{
        obj[node] = {};
        obj = obj[node];
      }
    }
  })
  return true;
}

// FOLLOWING 3 FUNCTIONS LOOP THROUGH FIELDSETS && CONDITIONAL FIELDSETS
// TO DO A DEEP SEARCH FOR FIELDS
export function getFieldsetFieldFromId(fieldsets, id) {
  let thisField = null;
  fieldsets.map(fieldset => {
    if(!thisField && fieldset.fields) {
      thisField = getFieldFromId(fieldset.fields, id);
    }
    return true;
  })
  return thisField;
}

export function getFieldFromId(fields, id) {
  let thisField = null;
  fields.map( field => {
    if(field.field_id === parseInt(id)) {
      thisField = field;

    }else if (!thisField && field.option_groups) {
      field.option_groups.map(option_group => {
        thisField = getOptionFieldFromId(option_group.options, id);
        return true;
      })
    }
    return true;
  })
  return thisField;
}

export function getOptionFieldFromId(options, id) {
  let thisField = null
  options.map(option => {
    if(!thisField && option.fieldsets) {
      thisField = getFieldsetFieldFromId(option.fieldsets, id);
    }
    return true;
  })
  return thisField;
}

export function getDefaultLanguage(languages, type = 'user') {
  switch(type) {
    case 'user':
      return languages.find(language => language.language_user_default && language.language_user_default === 1);
    case 'location':
      return languages.find(language => language.language_location_default && language.language_location_default === 1);
    default:
      return languages.find(language => language.language_default && language.language_default === 1);
  }


}

export function calculateTax(subtotal, tax) {
  let totalTax = 0;
  let arrTaxes = []
  if(Array.isArray(tax)) {
    tax.map(thisTax => {
      totalTax = totalTax + (subtotal * thisTax.tax_rate);
      arrTaxes.push({tax_id: thisTax.tax_id, tax_name: thisTax.tax_name, tax_value: (subtotal * thisTax.tax_rate)});
      return true;
    })
    return {total: totalTax, taxes: arrTaxes}
  } else if (Object.key(tax) > 0) {
    return {total: (subtotal*tax.tax_rate), taxes: {tax_id: tax.tax_id, tax_name: tax.tax_name, tax_value: (subtotal*tax.tax_rate)}}
  } else if (isNaN(parseFloat(tax))) {
    return subtotal*tax;
  }else {
    return null
  }
}

export function isUserLocation(location, user) {
  let userLocation = false;
  user.locations.map(uLocation => {
    if (uLocation.location_id === location.location_id &&
        uLocation.location_postal_code === location.location_postal_code &&
        uLocation.location_address_1 === location.location_address_1) {
          userLocation = true;
    }
    return true;   
  })
  // user.organizations.map(organization => {
  //   organization.locations.map(oLocation => {
  //     if (oLocation.location_id == location.location_id &&
  //         oLocation.location_postal_code == location.location_postal_code &&
  //         oLocation.location_address_1 == location.location_address_1) {
  //           userLocation = true;
  //         }
  //   })
  // })
  return userLocation;
}

export function getUserDefaults(user) {
  const organization_id = getUserDefaultOrgId(user);

  let userDefault = {organiation_id: organization_id,
                      location: user.organizations.filter(organization => organization.organization_id === organization_id)[0].locations.filter(location => location.location_default === 1)[0]};

  return userDefault;
}

export function getUserDefaultOrgId(user) {
  let organization_id;
  if(user.organizations){
    const userOrg = user.organizations.filter(organization => organization.organization_default === 1);
    organization_id = userOrg[0].organization_id;
  }else{
    organization_id = null;
  }
  return organization_id
}

export function creditCardDisplay(ccnum) {
  ccnum = ccnum.replace(/[^0-9.]/g,'');
  let cctype = creditCardGetType(ccnum);

  if(cctype === 'visa' || cctype === 'mastercard') {
    ccnum = 'XXXX XXXX XXXX '+ccnum.substr(12, 4);
  }else if (cctype === 'amex') {
    ccnum = 'XXXX XXXXXX '+ccnum.substr(10, 5);
  }else {
    let ccplaceholder = '';
    for(var i = 0; i < (ccnum.length-4); i++) {
      ccplaceholder += 'X';
    }
    ccnum = ccplaceholder+ccnum.substr(ccnum.length-5, 4);
  }
  return ccnum;
}

export function creditCardGetType(ccnum) {
  let cctype = null;
  if(ccnum.charAt(0) === '4') {
    cctype = 'visa';
  }else if((ccnum.length >= 2 && ['51', '52', '53', '54', '55'].includes(ccnum.substr(0,2))) ||
            (ccnum.length >= 4 && (parseInt(ccnum.substr(0,4)) >= 2221 && parseInt(ccnum.substr(0,4)) <= 2720))) {
    cctype = 'mastercard';
  }else if(ccnum.length >= 2 && (ccnum.substr(0,2) === '34' || ccnum.substr(0,2) === '37')) {
    cctype = 'amex';
  }

  return cctype;
}

export function creditCardNumberInput(ccnum) {
  ccnum = ccnum.replace(/[^0-9.]/g,'');
  let cctype = creditCardGetType(ccnum);

  if(cctype) {
    if(cctype === 'visa' || cctype === 'mastercard') {
      if(ccnum.length > 4 && ccnum.length <=8 ) {
        ccnum = ccnum.substr(0,4)+' '+ccnum.substr(4, ccnum.length-4);
      }else if (ccnum.length > 8 && ccnum.length <=12 ) {
        ccnum = ccnum.substr(0,4)+' '+ccnum.substr(4, 4)+' '+ccnum.substr(8, ccnum.length-8);
      }else if (ccnum.length > 12) {
        ccnum = ccnum.substr(0,4)+' '+ccnum.substr(4, 4)+' '+ccnum.substr(8, 4)+' '+ccnum.substr(12, 4);
      }
    }else if(cctype === 'amex') {
      if(ccnum.length > 4 && ccnum.length <= 10) {
        ccnum = ccnum.substr(0,4)+' '+ccnum.substr(4, ccnum.length-4);
      }else if(ccnum.length > 10) {
        ccnum = ccnum.substr(0,4)+' '+ccnum.substr(4, 6)+' '+ccnum.substr(10, 5);
      }
    }
  }
  return ccnum;
}

export function creditCardExpiryInput(expiry) {
  expiry = expiry.replace(/[^0-9.]/g,'');

  if(expiry.length >= 2) {
    expiry = expiry.substr(0,2)+'/'+expiry.substr(2, 2);
  }
  return expiry;
}

export function creditCardCVVInput(expiry) {
  expiry = expiry.replace(/[^0-9.]/g,'');
  expiry = expiry.substr(0,3);
  return expiry;
}

export function capitalizeString(string, titlecase = false) {
  if(typeof string === 'string') {
    if(string.match(/[A-Z][A-Z]/g) && !string.match(/[a-z]/g)) {
      string = string.toLowerCase();
    }
    if(!titlecase) {
      if(string.substring(0, 3) === 'www' ||
        string.substring(0, 4) === 'http' ||
        string.indexOf('@') > 0) {
        string = string.toLowerCase();  
      }else{
        string = string.charAt(0).toUpperCase()+string.substr(1, (string.length));  
      }
    }else{
      let aString = string.split(' ');
      let tcString = '';
      const custom_capitalizations = [{search: 'ebidet', display: 'eBidet'}];
      aString.map(node => {
        if(['a', 'an', 'the', 'and', 'but', 'for', 'at', 'by', 'from', 'etc.', 'pour', 'les', 'de'].includes(node.toLowerCase()) ||
          node.substring(0, 3).toLowerCase() === 'www' ||
          node.substring(0, 4).toLowerCase() === 'http') {
          tcString += node+' ';
        }else if(['hcp', 'rhc', 'nbrhc', 'lx', 'boxhot', 'debunk', 'cbd', 'thc', 'pei', 'p.e.i', 'og', 'mun', 'bc', 'mb', 'sk', 'ab', 'ns', 'pq', 'qc'].includes(node.toLowerCase())){
          tcString += node.toUpperCase()+' ';
        }else if(custom_capitalizations.find(word => word.search == node.toLowerCase())) {
          tcString += custom_capitalizations.find(word => word.search == node.toLowerCase()).display + ' ';
        }else{
          // NODE CALLED THESE ESCAPES UNNECESSARY node.charAt(0).match(/[\(\[\-]/g)
          if(node.charAt(0).match(/[([-]/g)) {
            tcString += node.charAt(0)+node.charAt(1).toUpperCase()+node.substr(2, (node.length))+' ';
          }else{
            tcString += node.charAt(0).toUpperCase()+node.substr(1, (node.length))+' ';
          }
        }
        return true;
      })
      string = tcString.trim();
    }

    return string;
  }else{
    return null;
  }

}

export function getBoolean(value) {
  switch(value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'ON':
      return true;
    case false:
    case 'false':
    case 0:
    case '0':
    case null:
    case '':
    case undefined:
      return false;
    default:
      return value;
  }
}

// Navigation Methods
export function setURLParams(param, value) {
  const pgURL = new URL(window.location);
  
  if(pgURL.searchParams.get(param) !== value) {
    pgURL.searchParams.set(param, value);
    window.history.pushState({}, '', pgURL.href);
  }
  
  return true;
}


export function getURLParams(pathname) {
  let pathNodes = pathname.split('/');

  let emptyNode = true;
  while(emptyNode) {
    emptyNode = false;
    for(var i = 0; i < pathNodes.length - 1; i++) {
      if(pathNodes[i] === '' || pathNodes[i].length === 0) {
        emptyNode = true;
        pathNodes.splice(i, 1);
      }
      if(emptyNode) {
        break;
      }
    }
  }

  let path = '';
  pathNodes.map(node => {
    if(path[path.length - 1] === '/') {
      path += '/'+node;
    }else{
      path += '/'+node;
    }
    return true;
  })
  const params = {section: pathNodes[1], activity: pathNodes[2], component: pathNodes[3], cpntactivity: pathNodes[4], cpntcomponent: pathNodes[5], }
  return params;
}

export function getURLSearchParams(params) {
  if(typeof params === 'string') {
    const paramNodes = params.replace('?', '').split('&');
    let objParams = {};
    Array.isArray(paramNodes) &&
    paramNodes.map(param => {
      const paramPair = param.split('=');
      objParams[paramPair[0]] = paramPair[1];
    })
    if(Object.entries(objParams).length > 0) {
      return objParams;
    }else{
      return false;
    }
  }else{
    return false;
  }
}

export function paramsToString(params) {
  if(Object.entries(params).length > 0) {
    let searchString = '?';
    let searchIndex = 0;
    Object.entries(params).map((param, index) => {
      if(param[0] && param[1]) {
        searchString += `${searchIndex > 0 ? '&' : ''}${param[0]}=${param[1]}`; 
        searchIndex++;
      }
    });
    if(searchString === '?') {
      return '';
    }else{
      return searchString;
    }
  }else{
    return '';
  }
}

export function splitFormData(formData) {
  const paramSplit = decodeURIComponent(formData).split('&');
  let data = {};
  paramSplit.map(param => {
    const paramPair = param.split('=');
    data[paramPair[0].replace('_en', '').replace('_fr', '').replace(/\+/g, ' ')] = paramPair[1].replace('_en', '').replace('_fr', '').replace(/\+/g, ' ');
    return true;
  })
  return data;
}

export function paramsGetBreadcrumb(params) {
  let paramTop = '';
  if(params && Object.entries(params).length > 0) {
    Object.entries(params).map((param, index) => {
      if(param[1] && param[1].length !== 0 && param[1] !== '') {
        if(index !== 0) {
          paramTop += ` > ${capitalizeString(param[1].toLowerCase().replace('-', ' '), true)}`;
        }else{
          paramTop = capitalizeString(param[1], true);
        }
      }
      return true;
    })
  }
  return paramTop;
}

export function getPropertyType(property) {
  if(property && typeof property !== 'object') {
    switch(property.toLowerCase()) {
      case 'width':
      case 'height':
      case 'depth':
        return {type: 'float', placeholder: '0.00 (In Inches)'};
      case 'largeur':
      case 'taille':
      case 'profondeur':
        return {type: 'float', placeholder: '0.00 (en pouces)'};
      case 'weight':
        return {type: 'float', placeholder: '0.00 (In Kilograms)'};
      default:
        return {type: 'text'};
    }
  }else{
    return {type: 'text'};
  }
}

export function verifyContent(reqInfo, info) {
    let results = true;
    if(Array.isArray(reqInfo) && reqInfo.length > 0) {
      reqInfo.map(thisInfo => {
        const pathNodes = thisInfo.name.split('.');
        let infoValue = info;
        pathNodes.map(node => {
          infoValue && (infoValue[node] || infoValue[node] === 0) ?
          infoValue = infoValue[node] :
          infoValue = null;
          return true;
        })
        if((infoValue  && infoValue.length > 0) || infoValue === 0 ) {
          let switchResults = false;
          switch(thisInfo.type) {
            case 'notEmpty':
              switchResults = true;
              break;
            case 'int':
              switchResults = parseInt(infoValue) >= 0;
              break;
            default:
              break;
          }
          if(results === true) {
            results = switchResults;
          }
        } else {
          results = false;
        }
        return true;
      })
      return results;
    }else {
      return false;
    }
  }

  export function reqInfoComplete(info, object) {
    let results = false;
    if(Array.isArray(info) && info.length > 0) {
      results = true;
      info.map( test => {
        const locationNodes = test.location.split('.');
        let stateVal = object

        locationNodes.map((node, index) => {
          if(stateVal[node]) {
            stateVal = stateVal[node];
            if(index+1 === locationNodes.length) {
              if(stateVal === null || stateVal === '') {
                results = false;
              }
            }
          }else{
            results = false;
          }
          return true;
        })
        return true
      })
    }
    return results;
  }

  export function mergeProducts(products) {
    let productMerge = {};
    if(Array.isArray(products)) {
      products.map((org, index) => {
        const organization_name = org.organization_name;
        const organization_id = org.organization_id;
        if(Object.entries(org.details).length > 0) {
          const languages = Object.keys(org.details);

          languages.map(language => {
            if(!productMerge[language]) {
              productMerge[language] = []
            }
            Array.isArray(org.details[language]) &&
            org.details[language].map(product => {
              return productMerge[language].push({...product, organization_name, organization_id});
            })
            return true;
          })
        }
        return true;
      })
    }
    return productMerge;
  }

  export function getObjectLanguages(object, type) {
    switch(type) {
      case 'product':
        if(object.details && Object.entries(object.details).length > 0) {
          return Object.keys(object.details);
        }else{
          return []
        }
      default:
        return [];
    }
  }

  export function getLanguageName(language_code) {
    switch(language_code) {
      case 'en':
        return 'English';
      case 'fr':
        return 'French';
      default:
        return null;
    }
  }

  export function getLanguageId(language_code) {
    switch(language_code) {
      case 'en':
        return 1;
      case 'fr':
        return 2;
      default:
        return null;
    }
  }


// SITE METHODS

  export function getDomain(url) {
    let location = {}
    return location;
  }
  
  export function localizeURL(url, domain = 'https://aecore.app') {
    let localURL = '/';
    let urlNodes = url.split('/');
    urlNodes.map(node => {
      if(node !== '' && !node.includes('.') && !node.includes(':') &&
        !['var', 'www', 'vhosts', 'http', 'httpdocs'].includes(node)) {
        localURL += `${node}/`;
      }
      return true;
    })
    return domain+localURL;
  }

  export function getCurrentStatus(statuses, language = 'en') {
    statuses = statuses.sort((a, b) => {return a.status_created < b.status_created ? 1 : -1  });
    return statuses[0][language].status_name;
  }

  export function statusExists(statuses, status, language = 'en') {
    if(statuses.find(sts => sts[language].status_name.toLowerCase() === status.toLowerCase())) {
      return true;
    }else{
      return false;
    }
  }

  export function domainPublic(host, sitePersona) {
    const reqSite = sitePersona.find(site => site.hosts.includes(host));

    if(reqSite && Object.entries(reqSite).length > 0 && reqSite.public) {
      return true;
    }else{
      return false;
    }
  }

  export function isCore(organization_id) {
    return [1, 2].includes(organization_id);
  }

  export function isPermitted(user_roles, organization_id, permission) {
    const permission_all = `${permission.split('_')[0]}_all`;
    let role = null;
    if(objExists(user_roles, `${organization_id}.role_name`)) {
      role = user_roles[organization_id];
    }else if(objExists(user_roles, `1.role_name`)) {
      role = user_roles['1'];
    }

    if(['Super User', 'Administrator'].includes(role.role_name) ||
        role.permissions.includes(permission) ||
        role.permissions.includes(permission_all)) {
      return true;
    }else{
      return false;
    }
  }

  // DISPLAY METHODS

  export function maskValue(value) {
    let valueMask = '';
    if(value) {
      for(var i=0; i<value.length; i++) {
        valueMask += '&#8226;';
      }
    }
    return valueMask;
  }

  // GENERATE

  export function generatePassword(pwLength = 8) {
    if(pwLength > 11) {
      pwLength = 11;
    }
    // GENERATE 8 CHARACTER RANDOM STRING
    let pw = Math.random().toString(36).slice(-pwLength);

    // SPECIAL CHARACTERS TO DRAW FROM TO SPLICE INTO PASSWORD
    const nonAlphNum = '@#$%^&*!';


    // CREATE A RANDOM SPECIAL CHARACTER COUNT BETWEEN 1 & 3
    let scCount = parseInt(Math.random()*3-1+1);

    // LOOP THROUGH AND INJECT THE ABOVE RANDOM NUMBER OF SPECIAL CHARACTERS
    for(var i = 0; i < scCount; i++) {
      const scPosition = parseInt(Math.random()*7);
      pw = pw.substring(0, scPosition) +
            nonAlphNum[parseInt(Math.random()*((nonAlphNum.length)-0)+0)] +
            pw.substring(scPosition+1, pw.length);
    }

    // CONVERT 1 OF THE LETTERS TO UPPER CASE
    let ucSet = false;
    while(!ucSet) {
      let ucPosition = parseInt(Math.random()*pw.length-1);
      if(pw[ucPosition].match(/[a-z]/g)) {
        pw = pw.substring(0, ucPosition) +
        pw[ucPosition].toUpperCase() +
        pw.substring(ucPosition+1, pw.length);
        ucSet = true;
      }
    }

    return pw;
  }
  
  // TASKS
  export function getActiveTasks(tasks, languageCode = 'en', isRoot = false) {
    if(Array.isArray(tasks) && tasks.length > 0) {
      let aTasks = []
      let curTaskSet = false;
      tasks.map(task => {
        let taskTree = [];
        if(task.task_published && !task.task_completed) {
          if(!isRoot || 
            (isRoot && !curTaskSet)) {
              let subtasks = [];
              if(task.subtasks) {
                subtasks = getActiveTasks(task.subtasks, languageCode, false);
              }
              
              aTasks.push({task_id: task.task_id,
                            task_name: task[languageCode] ? task[languageCode].task_name : languageCode,
                            task_owner: task.task_owner,
                            task_parent: task.task_parent,
                            type_name: task.type_name,
                            task_link_type: task.task_link_type,
                            task_link_to: task.task_link_to,
                            task_started: task.task_started,
                            task_completed: task.task_completed,
                            task_published: task.task_published,
                            users: task.users,
                            status: task.status,
                            subtasks: subtasks && subtasks.length > 0 ? subtasks : null});
              curTaskSet = true;  
          }
        }
        
        taskTree.length > 0 &&
        aTasks.push(taskTree);
      })
      return aTasks;
    }else{
      return null;
    }
  }
  
  export function buildTasks(task, taskParentName = null, taskParentType = null, taskParentLink = null) {
    if(['taskToProject', 'taskToOrder', 'taskToItem'].includes(task.task_link_type)) {
      taskParentName = task.task_name;
      taskParentType = task.task_link_type;
      taskParentLink = task.task_link_to;
    }
    
    let taskObj = [];
    if(task.subtasks && Array.isArray(task.subtasks) && task.subtasks.length > 0) {
      task.subtasks.map(subTask => {
        if(subTask.subtasks && Array.isArray(subTask.subtasks) && subTask.subtasks.length) {
          taskObj.push(...buildTasks(subTask, taskParentName, taskParentType, taskParentLink));
        }else{
          taskObj.push({...subTask, 
                        task_parent_name: taskParentName,
                        task_parent_type: taskParentType,
                        task_parent_link: taskParentLink});
        }
      })
    }else{
      taskObj.push({...task, 
                    task_parent_name: taskParentName,
                    task_parent_type: taskParentType,
                    task_parent_link: taskParentLink});
    }
    
    return taskObj;
  }
  
  export function itemIsDigital(item, languageCode = 'en') {
    let result = false;
    // TEST 1 : CUSTOM FIELD FOR DELIVERY:
    if(Array.isArray(objGetValue(item, `details.${languageCode}.fieldsets`))) {
      item.details[languageCode].fieldsets.map(fieldset => {
        if(Array.isArray(fieldset.fields)) {
          const deliveryField = fieldset.fields.find(field => field.field_label && field.field_label.toLowerCase() === 'delivery');
          if(deliveryField && 
              objGetValue(item, `field_values.${deliveryField.field_id}`) === 'digital') {
              result = true;
          }
        }
      })
    }
    if(result) {
      return result;
    }
    
    return result;
  }
  
  // CATEGORIES
  
  export function getCategory(categoryID, categories, breadcrumb = [], languageCode='en') {
    if(categoryID > 0 && Array.isArray(categories)) {
      let category;
      categories.map((cat, index) => {
        if(!category) {
          if(cat.category_id === parseInt(categoryID)) {
            category = {...cat, 
                        breadcrumb: [...breadcrumb, 
                                      {name: cat[languageCode].category_name,
                                      value: `/category/${cat.category_id}`}]};
          }else if(cat.subcategories &&
                Array.isArray(cat.subcategories) &&
                  cat.subcategories.length > 0) {
                category = getCategory(categoryID, cat.subcategories);
          }
        }
      });
      
      if(category) {
        return category;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  
  // COLOUR FUNCTIONS
  
  export function convertRGBToCMYK(rgb) {
    let clrR = rgb.r/255;
    let clrG = rgb.g/255;
    let clrB = rgb.b/255;
    
    let clrK = 1 - Math.max(rgb.r/255, rgb.g/255, rgb.b/255);
    let clrC = (1-clrR-clrK)/(1-clrK);
    let clrM = (1-clrG-clrK)/(1-clrK);
    let clrY = (1-clrB-clrK)/(1-clrK);
    
    return {
      c: Math.round(clrC*100), 
      m: Math.round(clrM*100), 
      y: Math.round(clrY*100), 
      k: Math.round(clrK*100)
    };
  }
  
  export function convertRGBToHex(rgb) {
    let clrR = rgb.r;
    let clrG = rgb.g;
    let clrB = rgb.b;
    return `${clrR.toString(16).toUpperCase()}${clrG.toString(16).toUpperCase()}${clrB.toString(16).toUpperCase()}`;
  }
  
  export function convertHexToRGB(hex) {
    if(typeof hex === 'string' && hex.length >= 6) {
      let clrR = parseInt(hex.substring(0, 2), 16);
      let clrG = parseInt(hex.substring(2,4), 16);
      let clrB = parseInt(hex.substring(4, 6), 16);
      
      return {r: clrR, g: clrG, b: clrB};
    }else{
      return null;
    }
  }
  
  export function convertCMYKtoRGB(cmyk) {
    let clrC = cmyk.c/100;
    let clrM = cmyk.m/100;
    let clrY = cmyk.y/100;
    let clrK = cmyk.k/100;
    
    let clrR = Math.round(255 * (1-clrC) * (1-clrK));
    let clrG = Math.round(255 * (1-clrM) * (1-clrK));
    let clrB = Math.round(255 * (1-clrY) * (1-clrK));
    
    return {r: clrR, g: clrG, b: clrB};
  }
  
  export function setRGBArray(colour) {
    let clr = colour;
    if(clr.match(/[\[\]]/g)) {
      clr = clr.replace(/[\[\]]/gi, '');
      if(clr.match(',')) {
        let aClr = clr.split(',');
        if(aClr.length === 3) {
          return {
            r: parseInt(aClr[0]),
            g: parseInt(aClr[1]),
            b: parseInt(aClr[2])
          };
        }else if(aClr.length === 4){
          let aCMYK = {
            c: parseInt(aClr[0]),
            m: parseInt(aClr[1]),
            y: parseInt(aClr[2]),
            k: parseInt(aClr[3])
          }
          return convertCMYKtoRGB(aCMYK);
        }else{
          return null;
        }
      }else {
        return null;	
      }
    }else if(clr.replace('#', '').length === 6){
      return convertHexToRGB(clr.replace('#', ''));
    }else{
      return null;
    }
  }