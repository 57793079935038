import React, {useEffect, useState, Fragment} from 'react';

import TruncatedText from 'components/atoms/TruncatedText';
import PropertySet from 'components/molecules/PropertySet';
import ProductCartCtl from 'components/products/ProductCartCtl';
import ProductChildren from 'components/products/ProductChildren';
import ProductLivePreview from 'components/products/ProductLivePreview';
import FormFieldValues from 'components/forms/form_display/FormFieldValues';
import MediaFile from 'components/profiles/media/MediaFile';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [languageCode, setLanguageCode] = useState(null);
  const [pricingOption, setPricingOption] = useState(0);
  const [hideLabel, setHideLabel] = useState(null);
  const [hidePricing, setHidePricing] = useState(null);
  const [useModal, setUseModal] = useState(null);
  const [product, setProduct] = useState(null);
  const [restriction, setRestriction] = useState(null);
  const [isShell, setIsShell] = useState(null);
  const [productApproval, setProductApproval] = useState(null);
  const [products, setProducts] = useState(null);
  const [fields, setFields] = useState(null);
  const [formFiles, setFormFiles] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [productNickname, setProductNickname] = useState(null);
  const [downloads, setDownloads] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [optionalCharges, setOptionalCharges] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }else if(!languageCode) {
      setLanguageCode('en');
    }
  }, [props.languageCode])
  
  useEffect(() => {
    if(props.objExists(props.site, `preferences`)) {
      if (props.site.preferences.hide_label && props.site.preferences.hide_label === true) {
        setHideLabel(true);
      }
    }
  }, [props.site]);
  
  useEffect(() => {
    if(props.hidePricing === true && hidePricing !== true) {
      setHidePricing(true);
    }
  }, [props.hidePricing]);
  
  useEffect(() => {
    if(props.useModal === false && useModal !== false) {
      setUseModal(false);
    }else{
      setUseModal(true);
    }
  }, [props.useModal]);
  
  useEffect(() => {
    if(props.product && product !== props.product) {
      setProduct(props.product);
    }else if (!props.product && product){
      setProduct(null);
    }
  }, [props.product]);
  
  useEffect(() => {
    if(props.restriction && restriction !== props.restriction) {
      setRestriction(props.restriction);
    }else if (!props.restriction && restriction){
      setRestriction(null);
    }
  }, [props.restriction]);
  
  useEffect(() => {
    if(props.isShell && !isShell) {
      setIsShell(props.isShell);
    }
  }, [props.isShell]);
  
  useEffect(() => {
    if(props.productApproval && 
        props.productApproval !== productApproval) {
        setProductApproval(props.productApproval);
    }
  }, [props.productApproval]);
  
  useEffect(() => {
    if(props.objExists(product, 'product_nickname') && 
        typeof product.product_nickname === 'string' &&
        product.product_nickname !== productNickname) {
      setProductNickname(product.product_nickname);
    }else if(productNickname && !props.objExists(product, 'product_nickname')) {
      setProductNickname(null);
    }
    
  }, [product]);
  
  useEffect(() => {
    if(languageCode && props.objExists(product, `details.${languageCode}.products`) && 
        Array.isArray(product.details[languageCode].products) && 
        product.details[languageCode].products !== products) {
          setProducts(product.details[languageCode].products);
    }
    
    if(Array.isArray(props.objGetValue(product, `selected_products`)) && 
        product.selected_products !== selectedProducts) {
          setSelectedProducts(product.selected_products);
    }else if(!Array.isArray(props.objGetValue(product, `selected_products`)) || 
              product.selected_products.length <= 0) {
          setSelectedProducts(null);
    }
        
    if(Array.isArray(props.objGetValue(product, `details.${languageCode}.downloads`)) &&
      product.details[languageCode].downloads !== downloads) {
      setDownloads(product.details[languageCode].downloads);
    }
  }, [product, languageCode]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(product, `details.${languageCode}.fieldsets`)) &&
        Object.entries(props.objGetValue(props.form, `tmpFormData.productFieldValues`)).length > 0) {
        let productFields = [];
        product.details[languageCode].fieldsets.map(fieldset => {
          if(Array.isArray(fieldset.fields)) {
            fieldset.fields.map(field => {
              productFields.push(field);
            })
          }
        })
        
        let suppliedFields = [];
        let tempOptionalCharges = [];
        Object.keys(props.form.tmpFormData.productFieldValues).map(key => {
          const fieldInfo = productFields.find(pField => pField.field_id === parseInt(key));
          if(fieldInfo && props.form.tmpFormData.productFieldValues[key]) {
            let fieldValue;
            if (props.form.tmpFormData.productFieldValues[key] === true || props.form.tmpFormData.productFieldValues[key] === 'true') {
              fieldValue = 'Yes';
              if (Array.isArray(props.objGetValue(fieldInfo, `charges`)) && Object.entries(props.objGetValue(fieldInfo, `charges`)).length > 0) {
                fieldInfo.charges.map((charge, index) => {
                  tempOptionalCharges.push(charge);
                }) 
              }
            } else if (props.form.tmpFormData.productFieldValues[key] === false || props.form.tmpFormData.productFieldValues[key] === 'false') {
              fieldValue = 'No';
            } else {
              fieldValue = props.form.tmpFormData.productFieldValues[key];
            }
            suppliedFields.push({...fieldInfo, field_value: fieldValue});
          }
        });
        if(suppliedFields.length > 0) {
          setFields(suppliedFields);
        }else if (fields) {
          setFields(null);
        }
        setOptionalCharges(tempOptionalCharges);
    }
  }, [product, languageCode, props.form]);
  
  useEffect(() => {
    if(props.objExists(props.files, 'uploads') && 
      props.files.uploads !== formFiles) {
      setFormFiles(props.files.uploads);
    }else if(!props.objExists(props.files, 'uploads') &&
      formFiles) {
      setFormFiles(null); 
    }
   }, [props.files.uploads]);
   
  useEffect(() => {
    if(props.pageCount && 
        props.pageCount !== pageCount) {
        setPageCount(props.pageCount);
    }
  }, [props.pageCount]);
  
  let properties = {};
  props.product && props.product.properties && props.product.properties.map(prop => {
    return properties[prop.property_name] = prop.property_value;
  })
  
  return (
    <div className="product-details">
      {product && languageCode ? 
        <Fragment>
          {props.objExists(props.product, `details.${languageCode}.product_name`) && 
          <h3>{props.objExists(props.product, `details.${languageCode}.product_end`) ? props.capitalizeString(props.product.details[languageCode].product_name, true) + " (Expires " + props.dateDisplay(props.product.details[languageCode].product_end) + ")" : props.capitalizeString(props.product.details[languageCode].product_name, true)}</h3>
          }
          
          {props.objExists(props.product, `details.${languageCode}.product_sku`) && 
            <div><b>Product SKU:</b> {props.product.details[languageCode].product_sku}</div>
          }
          
          {props.objExists(props.product, `details.${languageCode}.product_code`) && props.product.details[languageCode].product_code != props.product.details[languageCode].product_sku && 
            <div><b>Product Code:</b> {props.product.details[languageCode].product_code}</div>
          }
          
          {props.objExists(props.product, `details.${languageCode}.product_bundle`) &&
            <div><b>Bundled:</b> In groups of {props.product.details[languageCode].product_bundle}</div>
          }
          
          {pageCount && pageCount > 0 && 
            <div><b>Page Count:</b> {pageCount}</div>
          }
          
          {props.objExists(props.product, `details.${languageCode}.product_description`) &&
            <div className="margin-top-1em" dangerouslySetInnerHTML={{__html: props.product.details[languageCode].product_description}} />
          }
          
          {props.objExists(props.product, `details.${languageCode}.product_created`) &&
            <p className="margin-top-1em subtext">
              Added {props.dateDisplay(props.product.details[languageCode].product_created)}
              {props.objExists(props.product, `details.${languageCode}.product_created`) &&
                props.objExists(props.product, `details.${languageCode}.product_modified`) && 
                props.product.details[languageCode].product_modified > props.product.details[languageCode].product_created &&
                <Fragment>
                  <br />
                  Updated {props.dateDisplay(props.product.details[languageCode].product_modified)}
                </Fragment>
              }
            </p>
          }
          
          {props.objExists(props.product, `details.${languageCode}.properties`) &&
            Array.isArray(props.product.details[languageCode].properties) &&
            props.product.details[languageCode].properties.length > 0 &&
            <PropertySet {...props} 
                          properties={props.product.details[languageCode].properties} />
          }
          
          {Array.isArray(downloads) &&
            <Fragment>
            <h4>Product Downloads</h4>
            {downloads.map((download, index) => {
              return (
                <div key={`pDLdiv${index}`} className="form-row">
                  <MediaFile {...props}
                      key={`pDL${index}`}
                      media={download}
                      />
                </div>
              )
            })}
            </Fragment>
          }
          
          {productNickname &&
            <div className="form-row">
              <b>Item Label</b>&nbsp;
              {productNickname}
            </div>
          }
          
          {fields && !isShell &&
            <FormFieldValues {...props}
                title="Custom information"
                fields={fields}
                formFiles={formFiles}
                />
          }
          
          {product && languageCode &&
            <ProductCartCtl
              {...props}
              hideLabel={hideLabel}
              hidePricing={props.hidePricing}
              useModal={props.useModal}
              pricing={props.objExists(product, `details.${languageCode}.pricing`) ? 
                        product.details[languageCode].pricing : null}
              pricingOption={pricingOption}
              pricingOptionAction={setPricingOption}
              customizable={props.objExists(product, `details.${languageCode}.fieldsets`) && 
                            product.details[languageCode].fieldsets.length > 0 ? true : false}
              product={product}
              restriction={restriction}
              products={products}
              selectedProducts={selectedProducts}
              pageCount={pageCount}
              optionalCharges={optionalCharges}
              languageCode={languageCode}
              handleCartAdd={props.handleCartAdd}
            />
          }                    
        </Fragment>
        :
        <Loader />
      }
      </div>  
  )
}
