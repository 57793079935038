import React, {useEffect, useState, Fragment} from 'react';

import IconSVG from 'components/icons/IconSVG';
import Link from 'components/navigation/Link';
import Loader from 'components/atoms/Loader';
import TaskPractical from 'components/profiles/tasks/TaskPractical';
import TaskApproval from 'components/profiles/tasks/TaskApproval';
import TaskAdmin from 'components/profiles/tasks/TaskAdmin';
import TaskGeneral from 'components/profiles/tasks/TaskGeneral';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [isAdmin, setIsAdmin] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [tasks, setTasks] = useState(null);
	const [approval, setApproval] = useState(null);
	const [isUpdating, setIsUpdating] = useState(null);
	const [userTaskAvailable, setUserTaskAvailable] = useState(false);
	const [active, setActive] =  useState(null);
	
	// HOOKS
	useEffect(() => {
		props.userID && 
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token && 
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID && 
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	useEffect(() => {
		props.languageCode && 
		props.languageCode !== languageCode &&
		setLanguageCode(props.languageCode);
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.isAdmin && !isAdmin) {
		  setIsAdmin(true);
		}else if(!props.isAdmin && isAdmin) {
		  setIsAdmin(false);
		}
	  }, [props.isAdmin]);
	
	useEffect(() => {
		props.userAccess &&
		props.userAccess !== userAccess &&
		setUserAccess(props.userAccess);
	}, [props.userAccess]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	  useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(props.tasks && 
			props.tasks !== tasks) {
			
			setTasks(props.tasks);
		}
	}, [props.tasks, isUpdating]);
	
	useEffect(() => {
		if(userID && 
			tasks && 
			Array.isArray(tasks) &&
			tasks.length > 0) {
			let userTask =  false;
			
			tasks.map(task => {
				if(task.task_owner > 0 &&
					task.task_owner === userID) {
					userTask = true;
				}else if(userID &&
					Array.isArray(props.objGetValue(task, 'users.linked'))) {
					const taskUser = task.users.linked.find(user => user.user_id === userID);
					if(taskUser) {
						userTask =  true;
					}
				}else if(Array.isArray(props.objGetValue(props.user, 'user_groups')) &&
						Array.isArray(props.objGetValue(task, 'users.groups'))) {
					// 	console.log("GROUP user groups and tasks groups are array");
					// task.users.groups.map(group => {
					// 	console.log("GROUP Mapping group:", group.group_id,  props.user.user_groups.find(uGroup => group.group_id === uGroup.group_id));
					// 	if(props.user.user_groups.find(uGroup => group.group_id === uGroup.group_id)) {
					// 		userTask = true;
					// 	}
					// })
				}
			});
			if(userTask) {
				setUserTaskAvailable(true);
			}
		}
		
	}, [tasks, userID, isUpdating]);
	
	useEffect(() => {
		if(tasks && isUpdating) {
			let updating = isUpdating;
			
			if(Array.isArray(updating)) {
				updating.map(update => {
					if(update.taskID) {
						const taskUpdate = tasks.find(task => task.task_id === update.taskID);
						if(taskUpdate) {
							if(taskUpdate.status[0][languageCode].status_name === update.status) {
								updating = updating.filter(task => task.task_id !== update.taskID);
							}
						}
					}
				})
			}
			if(updating && updating.length > 0 && updating !== isUpdating) {
				
				setIsUpdating(updating);	
			}else if(isUpdating){
				setIsUpdating(null);
			}
		}
	}, [tasks]);
	
	useEffect(() => {
		if(userTaskAvailable) {
			setActive(true);
		}
	}, [userTaskAvailable]);
	
	function taskUpdate(taskID, status) {
		let updateSet;
		if(Array.isArray(isUpdating)) {
			updateSet = isUpdating.filter(update => update.taskID !== taskID);
			updateSet.push({taskID: taskID, status: status});
		}else{
			updateSet = [{taskID: taskID, status: status}];
		}
		setIsUpdating(updateSet)
	}
	
	return (
		<div className={`task-set${active ? ' active' : ''}`}>
		{tasks && Array.isArray(tasks) && tasks.length > 0 &&
		tasks.map((task, index) => {
			let taskAccess = {};
			
			if(task.task_owner > 0 &&
				task.task_owner === userID) {
				taskAccess['owner'] = true;
			}
			if(userID &&
				Array.isArray(props.objGetValue(task, 'users.linked'))) {
				const taskUser = task.users.linked.find(user => user.user_id === userID);
				if(taskUser) {
					taskAccess['linked'] =  true;
				}
			}
			if(Array.isArray(props.objGetValue(props.user, 'user_groups')) &&
				Array.isArray(props.objGetValue(task, 'users.groups'))) {
				task.users.groups.map(group => {
					if(props.user.user_groups.find(uGroup => group.group_id === uGroup.group_id)) {
						taskAccess['group'] = true;
					}
				})
			}
			return (
				taskAccess.owner || (task.task_owner <= 0 && (taskAccess.linked || taskAccess.group)) ?
					<div key={`tskinln${index}`} className={`task-inline${(taskAccess.owner || (task.task_owner <= 0 && taskAccess.linked)) && !task.task_completed ? ' action' : ''}`}>
						{(taskAccess.owner || (task.task_owner <= 0 && taskAccess.linked)) && 
							!task.task_completed &&
							<div className="task-header">
								<IconSVG icon="flame" />
								Action Required
							</div>
						}
						{task.type_name !== 'approval' &&
						<div className="task-info">
							<div>
								<p>{props.capitalizeString(task.task_name, true)}</p>
								{isUpdating && isUpdating.find(update => update.taskID === task.task_id) ?
									<p className="status">
										<Loader label="Updating..." />
									</p>
								:
									<Fragment>
									{props.objExists(task, `status[0].${props.languageCode}.status_name`) &&
									props.objGetValue(task, `status[0].${props.languageCode}.status_name`) !== 'new' ?
										<p className="status">
											{props.capitalizeString(task.status[0][languageCode].status_name, true)}
										</p>
									:
										<p className="status">Pending...</p>
									}
								</Fragment>
								}
							</div>
							
						</div>
						}
						<div className="task-ctl">
							{task.type_name === 'approval' &&
							<TaskApproval {...props}
											key={`tskinlnapprvl${index}`}
											userID={userID}
											token={token}
											organizationID={organizationID}
											languageCode={languageCode}
											isAdmin={isAdmin}
											location={props.location}
											source={props.source}
											userAccess={userAccess}
											canEdit={canEdit}
											isSuper={isSuper}
											task={task}
											taskUpdating={
												isUpdating && Array.isArray(isUpdating) &&
												isUpdating.find(update => update.taskID === task.task_id) ?
													true : false
											}
											taskOwnerType={props.taskOwnerType}
											taskOwnerID={props.taskOwnerID}
											taskRootType='taskToProject'
											taskRootID={props.taskRootID}
											onUpdate={setIsUpdating}
											taskAccess={taskAccess}
											actionUpdate={taskUpdate} />
							}
							{task.type_name === 'practical' &&
							<TaskPractical {...props}
											key={`tskinlnprctcl${index}`}
											userID={userID}
											token={token}
											organizationID={organizationID}
											languageCode={languageCode}
											isAdmin={isAdmin}
											location={props.location}
											source={props.source}
											userAccess={userAccess}
											canEdit={canEdit}
											isSuper={isSuper}
											task={task}
											taskOwnerType={props.taskOwnerType}
											taskOwnerID={props.taskOwnerID}
											taskRootType='taskToProject'
											taskRootID={props.taskRootID}
											onUpdate={setIsUpdating}
											taskAccess={taskAccess}
											actionUpdate={taskUpdate} />
							}
							{task.type_name === 'admin' &&
							<TaskAdmin {...props}
											key={`tskinlnadmn${index}`}
											userID={userID}
											token={token}
											organizationID={organizationID}
											languageCode={languageCode}
											isAdmin={isAdmin}
											location={props.location}
											source={props.source}
											userAccesss={userAccess}
											canEdit={canEdit}
											isSuper={isSuper}
											task={task}
											taskOwnerType={props.taskOwnerType}
											taskOwnerID={props.taskOwnerID}
											taskRootType='taskToProject'
											taskRootID={props.taskRootID}
											onUpdate={setIsUpdating}
											taskAccess={taskAccess}
											actionUpdate={taskUpdate} />
							}
						</div>
					</div>
				:
					
					<TaskGeneral {...props}
								key={`tskinlngnrl${index}`}
								userID={userID}
								token={token}
								organizationID={organizationID}
								languageCode={languageCode}
								isAdmin={isAdmin}
								location={props.location}
								source={props.source}
								userAccess={userAccess}
								canEdit={canEdit}
								isSuper={isSuper}
								task={task}
								taskOwnerType={props.taskOwnerType}
								taskOwnerID={props.taskOwnerID}
								taskRootType='taskToProject'
								taskRootID={props.taskRootID}
								onUpdate={setIsUpdating}
								taskAccess={taskAccess}
								actionOnUpdate={taskUpdate} />
			)
		})
	}
	</div>
	)
}