import React, {useEffect, useState, Fragment} from 'react';

import IconSVG from 'components/icons/IconSVG';
import IconFile from 'components/icons/IconFile';
import Switch from 'components/forms/elements/Switch';
import ActionsMenu from 'components/organisms/ActionsMenu';

import * as utils from 'methods/site';

export default (props) => {
  const [media, setMedia] = useState(null);
  const [mediaPath, setMediaPath] = useState(null);
  const [mediaFileName, setMediaFileName] = useState(`/assets/media/aeImageUnavailable.jpg`);
  const [mediaSource, setMediaSource] = useState(null);
  const [mediaID, setMediaID] = useState(null);
  const [mediaPublished, setMediaPublished] = useState(false);
  const [mediaFileTag, setMediaFileTag] = useState(false);
  const [mediaType, setMediaType] = useState(false);
  const [mediaFileUploadName, setMediaFileUploadName] = useState(null);
  const [mediaCaption, setMediaCaption] = useState(null);
  const [mediaFileSize, setMediaFileSize] = useState(null);
  
  
  useEffect(() => {
    // if(props.media && Object.entries(props.media) && utils.objExists(props.media, 'media_file_name')) {
    //   setMedia(props.setFilePath(props.media.media_file_name, props.localizeURL(props.media.media_file_path)));
    // }
    if(props.media && props.media !== media) {
      setMedia(props.media);
    }
  }, [props.media]);
  
  useEffect(() => {
    if(props.mediaPath && props.mediaPath !== mediaPath) {
      setMediaPath(props.mediaPath);
    }else{
      setMediaPath(`${window.location.hostname === 'localhost' ? 'http://aecore.co' : 'https://aecore.app'}/assets/media/`); 
    }
  }, [props.mediaPath]);
  
  useEffect(() => {
    if(props.mediaFileName && props.mediaFileName !== mediaFileName) {
      setMediaFileName(props.mediaFileName);
    }else if(!props.mediaFileName && 
              media && media.media_file_name &&
              media.media_file_name !== mediaFileName){
      setMediaFileName(media.media_file_name);
    }
  }, [props.mediaFileName, media]);
  
  useEffect(() => {
    if(mediaFileName && mediaPath) {
      const fileSrc = utils.setFilePath(mediaFileName, mediaPath);
      if(fileSrc !== mediaSource) {
        setMediaSource(fileSrc);
      }
    }
    
  }, [mediaFileName, mediaPath]);
  
  useEffect(() => {
    if(media && media.media_file_tag) {
      if(['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(media.media_file_tag)) {
        setMediaType('image');
      }else{
        setMediaType('file');
      }
    }else if(!media && props.media_type) {
      setMediaType(props.media_type);
    }
  }, [media, props.media_type]);
  
  useEffect(() => {
    if(utils.objExists(media, 'media_id') && 
              media.media_id !== mediaID) {
      setMediaID(media.media_id); 
    }else if(!media && props.media_id && 
              props.media_id !== mediaID) {
      setMediaID(props.media_id);
    }
  }, [media, props.media_id]);
  
  useEffect(() => {
    if(media && media.media_published && 
        !mediaPublished) {
        setMediaPublished(true); 
      }else if(!media && props.media_published && !mediaPublished) {
        setMediaPublished(true);
      }
  }, [media, props.media_published]);
  
  useEffect(() => {
    if(media && media.media_file_tag &&
      media.media_file_tag !== mediaFileTag) {
      setMediaFileTag(media.media_file_tag);
    }else if(!media && props.media_file_tag &&
      props.media_file_tag !== mediaFileTag) {
      setMediaFileTag(props.media_file_tag);
    }
  }, [media, props.media_file_tag]);
  
  useEffect(() => {
    if(media && media.media_file_upload_name &&
      media.media_file_upload_name !== mediaFileUploadName) {
      setMediaFileUploadName(media.media_file_upload_name);
    }else if(!media && props.media_file_upload_name &&
      props.media_file_upload_name !== mediaFileUploadName) {
      setMediaFileUploadName(props.media_file_upload_name);
    }
  }, [media, props.media_file_upload_name]);
  
  useEffect(() => {
    if(media && media.media_caption &&
      media.media_caption !== mediaCaption) {
      setMediaCaption(media.media_caption);
    }else if(!media && props.media_caption &&
      props.media_caption !== mediaCaption) {
      setMediaCaption(props.media_caption);
    }
  }, [media, props.media_caption]);
  
  useEffect(() => {
    if(media && media.media_file_size &&
      media.media_file_size !== mediaFileSize) {
      setMediaFileSize(media.media_file_size);
    }else if(!media && props.media_file_size &&
      props.media_file_size !== mediaFileSize) {
      setMediaFileSize(props.media_file_size);
    }
  }, [media, props.media_file_size]);

  // FUNCTIONS


  // props.fileExists is causing extreme slowdown in Chrome; Need to find alternative;
  return (
    <div className={`media-hero${props.className ? ` ${props.className}` : ''}`}>
      {mediaType === 'file' ?
        <div className="media-hero-file">
          <IconFile {...props} icon={mediaFileTag} />
          <div className="file-icon-caption">
            <div className="file-icon-caption-content">
              <h4>{`${mediaCaption ? mediaCaption : mediaFileUploadName}`}</h4>
              <div className="file-icon-caption-info">
              {`${mediaFileTag} | ${utils.displayFileSize(mediaFileSize)}`}
              </div>
            </div>
            <div className="file-icon-caption-ctl">
              <ActionsMenu
                actions={[
                  {label: 'Edit', icon: 'edit', action: ()=>{props.actionEdit(mediaID)}},
                  {label: 'Remove', icon: 'trash', action: ()=>{props.actionRemove(mediaID)}},
                ]}/>
            </div>
          </div>
        </div>
      :
        <Fragment>
        <img src={mediaSource ? mediaSource : 'https://aecore.app/assets/media/aeImageUnavailable.jpg'}
              alt={mediaCaption ? mediaCaption : mediaFileName}
              ref={props.imgRef ? props.imgRef : null}
              className={props.imgClass ? props.imgClass : null} />
        {mediaCaption &&
          <div className="media-hero-caption">
            {utils.capitalizeString(mediaCaption, false)}
          </div>
        }
        {props.isAdmin && props.userID && props.token &&
          <div className="media-hero-ctl">
            <div className="media-hero-ctl-action" 
                onClick={()=>{props.actionEdit(mediaID)}}>
                <IconSVG icon="edit" /> Edit Info
            </div>
            <div className="media-hero-ctl-action" 
                  onClick={()=>{props.actionRemove(mediaID)}}>
              <IconSVG icon="trash" /> Remove
            </div>
            <div className="media-hero-ctl-action" 
                  onClick={()=>{props.actionPublish(mediaID)}}>
              <IconSVG icon={mediaPublished ? 'hide' : 'eye'} /> {mediaPublished ? 'Unpublish' : 'Publish'}
            </div>
          </div>
        }
        </Fragment>
      }
    </div>
  )
}
