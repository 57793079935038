import React, {useEffect, useState} from 'react';

import * as utils from 'methods/site';

import OrderList from 'components/profiles/orders/OrderList';
import Loader from 'components/atoms/Loader';

export default (props) => {
  props = {...props.children, ...utils};
  
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [orders, setOrders] = useState(null);
  
  useEffect(() => {
    props.objExists(props.user, 'user_id') &&
    setUserID(props.user.user_id);

    if(sessionStorage.getItem('token')) {
      setToken(sessionStorage.getItem('token'))
    }else if(localStorage.getItem('token')) {
      setToken(localStorage.getItem('token'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  useEffect(() => {
    if(props.objExists(props.site, 'persona.organization_id')) {
      setOrganizationID(props.site.persona.organization_id);
      setOrganizationName(props.site.persona.organization_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.site.persona]);
  
  useEffect(() => {
    if(userID &&
        (!props.objExists(props.user, 'orders')) || 
        (props.user.orders && 
          (!Array.isArray(props.user.orders) || props.user.orders.length <= 0))) {
      let orderParams = [
        {name: 'process', value: 'get_projects'},
        {name: 'core_user', value: userID},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
      ]
      if(![1, 2].includes(organizationID)) {
        orderParams.push({name: 'organization_id', value: organizationID});  
      }
      props.userLoadState(orderParams, 'orders', 'orders');
    }
  }, [userID, token]);

  useEffect(() => {
    if(props.objExists(props.user, 'orders')) {
      if(Array.isArray(props.user.orders)) {
        let userOrders = props.user.orders;
        userOrders.sort((a, b)=>{return a.project_created > b.project_created ? -1 : 1});
        setOrders(userOrders);
      }else{
        setOrders(props.user.orders);
      }
    }
      
  }, [props.user.orders]);

  return (
     <article className="user">
      <h2 className="margin-bottom-1em">{props.objExists(props.user, 'user_id')  ? `${props.user.contact.contact_name_first} ${props.user.contact.contact_name_last} ` : 'User '}Orders</h2>
      {orders ?
          Array.isArray(orders) && orders.length > 0 ?
            <OrderList {...props}
                      isAdmin={false}
                      orders={orders}
                      userID={userID}
                      token={token}
                      organizationID={![1,2].includes(organizationID) ? organizationID : null}
                      organizationName={organizationName}
                      location="user"
                      showBy="user" />
          :
            <p>
              You have no orders in the system
            </p>
        :
          <Loader />
      }
    </article>
  )
}
