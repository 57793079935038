import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Card from 'components/atoms/Card';
import CardContent from 'components/molecules/CardContent';
import CoreRule from 'components/atoms/CoreRule';
import FormDynamic from 'components/forms/FormDynamic';
import FormProgressive from 'components/forms/FormProgressive';

import ModalLogin from 'components/molecules/ModalLogin';


export default (props) => {
  const [accountCredentials, setCredentials] = useState(null);
  const [accountError, setAccountError] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const [passwordMatch, setPasswordMatch] = useState(null);
  const [errorActive, setErrorActive] = useState(false);
  const [errorEmailValue, setErrorEmailValue] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const [verifiedFields, setVerifiedFields] = useState([]);

  useEffect(() => {
    setCredentials(buildFields());
  }, []);

  useEffect(() => {
    setCredentials(buildFields());
  }, [props.cart.project, activeField]);

  useEffect(() => {
    //console.log("buidling field passwordMatch", passwordMatch);
    setCredentials(buildFields());
  }, [passwordMatch]);

  useEffect(() => {
    // !errorActive && setAccountError(null);

    if(props.objExists(props.user, 'error.duplicateUser') &&
      props.user.error.duplicateUser) {
      setAccountError(<div className="progressive-form-error">
                      A user with this email already exists. If this is your account, <span className="action-text" onClick={openLogin}>Login Here</span> to check out.
                      </div>);
      setErrorEmailValue(props.cart.project.billing.contact.contact_email);
      props.cartSetInfo(null, 'project.billing.contact.contact_email');
      setErrorActive(true);
      setActiveField(0);
      setErrorFields([...errorFields, 'project.billing.contact.contact_email']);
      props.userRemoveError('duplicateError');
    }else{

    }
  }, [props.user.error]);

  useEffect(() => {
    let passRes = null;
    // !errorActive && setAccountError(null);
    if(props.objExists(props.cart, 'project.billing.contact.password_verify') &&
        !verifyPassword(props.cart.project.billing.contact.password_verify)) {
      setAccountError(<div className="progressive-form-error">
                      Your password and password verification do not match. Please ensure they are identical.
                      </div>);
    setPasswordMatch(false);
    setErrorActive(true);
    setActiveField(2);
    setErrorFields([...errorFields, 'project.billing.contact.password_verify']);

    }else{
      setPasswordMatch(true);
      setErrorFields(errorFields.filter(eField => eField !== 'project.billing.contact.password_verify'));
    }
  }, [props.cart.project]);

  useEffect(() => {
    if(props.objExists(props.cart, 'project.billing.contact.organization_name') && props.cart.project.billing.contact.organization_name !== '' &&
      (!props.objExists(props.cart, 'project.contact.orgranization_id') || !props.cart.project.contact.organization_id >= 0)) {
        //console.log("We should be setting this now");
        props.cartSetInfo(0, 'project.billing.contact.organization_id');
    }
  }, [props.objExists(props.cart, 'project.billing.contact.organization_name') && props.cart.project.billing.contact.organization_name])

  function buildFields() {
    return [
      {fieldset_name: 'Account Info',
        fields: [
        {field_type: 'email',
        field_name: "project.billing.contact.contact_email",
        field_label: "Email",
        field_placeholder: "Your Contact Email",
        field_value: props.objExists(props.cart, 'project.billing.contact.contact_email') ?
                      props.cart.project.billing.contact.contact_email : null,
        field_required: true,
        field_validate: true,
        field_actions: {update: checkDuplicateEmail, input: clearErrors, validate: props.isEmail},
        field_errors: errorFields,
        field_verified: verifiedFields},
        {field_type: 'text',
        field_name: 'project.billing.contact.organization_name',
        field_label: 'Company',
        field_placeholder: 'Company name if applicable',
        field_value: props.objExists(props.cart, 'project.billing.contact.organization_name') ?
                      props.cart.project.billing.contact.organization_name : null,
        field_required: false,
        field_validate: false},
        {field_type: 'password',
        field_name: "project.billing.contact.password",
        field_label: "Password",
        field_value: props.objExists(props.cart, 'project.billing.contact.password') ?
                      props.cart.project.billing.contact.password : null,
        field_placeholder: "Minium 8 characters",
        field_required: true,
        field_validate: false,
        field_actions: {validate: props.isPassword},
        field_errors: errorFields,
        field_verified: verifiedFields},
        {field_type: 'password',
        field_name: "project.billing.contact.password_verify",
        field_label: "Verify Password",
        field_value: props.objExists(props.cart, 'project.billing.contact.password_verify') ?
                      props.cart.project.billing.contact.password_verify : null,
        field_placeholder: "Repeat your password",
        field_required: true,
        field_validate: false,
        field_activate_accept: passwordMatch,
        field_actions: {input: clearErrors, validate: verifyPassword},
        field_errors: errorFields ? errorFields : null},
      ]}
    ]
  }

  function openLogin(e) {
    props.setModal(<ModalLogin {...props} formAction={props.userLogin} unmountOnClick={true} />);
  }

  function checkDuplicateEmail(contact_email) {
    props.userCheckEmail(contact_email);
  }

  function clearErrors(field) {
    if(field.value !== errorEmailValue) {
        setAccountError(null);
        setErrorActive(false);
        if(errorFields.includes(field.name)) {
          setErrorFields(errorFields.filter( eField => eField !== field.name));
        }
      }
  }

  function verifyPassword(value) {
    if(props.objExists(props.cart, 'project.billing.contact.password') &&
      value === props.cart.project.billing.contact.password) {
      return true;
    }else{
      return false;
    }
  }

  return (
    <Card editable={false}
          title="Create an Account">
      <div className="flex-row">
        <CardContent title="Account Info" className="progressive-form" >
          {accountError && accountError}
          <FormProgressive {...props} fieldsets={accountCredentials ? accountCredentials : buildFields()}
                                      activeField={activeField}
                                      formActions={[]} />
        </CardContent>
        <CoreRule vertical={true} />
        <CardContent title="Create an Account">
          <p>Your account makes checkout easy and lets you track orders.</p>
          <p>Already have an account? <span className="action-text" onClick={openLogin}>Login Here</span> to check out.</p>
        </CardContent>
      </div>
    </Card>
  )
}
