import React, {useEffect, useState, Fragment} from 'react';

import InputNumberCtl from 'components/forms/elements/InputNumberCtl';

export default (props) => {
	const [qtyOptions, setQtyOptions] = useState(null);
	
	useEffect(() => {
		if(props.options && props.options !== qtyOptions) {
			setQtyOptions(props.options);
		}
		
	}, [props.options]);
	
	return (
		<div className="qty-list">
			{props.label &&
				<div className="label">
					{props.label}
				</div>	
			}
			
			{Array.isArray(qtyOptions) &&
				qtyOptions.map((option, index) => {
					return(
						Array.isArray(option.options) ?
							option.options.map((gOption, gIndex) => {
								return(
									<InputNumberCtl {...props}
											key={`frmElmQtyList${gIndex}`}
											label={gOption.option_label}
											name={gOption.option_name}
											value={gOption.option_value} />
								)
							})
						:
						<InputNumberCtl {...props}
							key={`frmElmQtyList${index}`}
							label={option.option_label}
							name={option.option_name}
							value={option.option_value} />
						
					)
				})
			}
		
		</div>
	)
}