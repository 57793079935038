import React, {useEffect, useState, useRef, Fragment} from 'react';

import CartAddSuccess from 'components/cart/CartAddSuccess';

import FormMsg from 'components/molecules/FormMsg';
import Button from 'components/forms/elements/Button';
import Select from 'components/forms/elements/Select';
import FormDynamic from 'components/forms/FormDynamic';
import FormDialogBox from 'components/forms/FormDialogBox';
import ProductInputQty from 'components/products/ProductInputQty';
import ProductPrice from 'components/products/ProductPrice';
import IconSVG from 'components/icons/IconSVG';
import Link from 'components/navigation/Link';
import Input from 'components/forms/elements/Input';
import Control from 'components/atoms/Control';

export default (props) => {
  const [product, setProduct] = useState(null);
  const [productStock, setProductStock] = useState(null);
  const [restriction, setRestriction] = useState(null);
  const [restrictionQtyAvailable, setRestrictionQtyAvailable] = useState(null);
  const [qty, setQty] = useState(0);
  const [priceMsg, setPriceMsg] = useState(null);
  const [pricingOption, setPricingOption] = useState('0');
  const [pricingCtlQty, setPricingCtlQty] = useState(false);
  const [pageCount, setPageCount] = useState(null);
  const [pagePricingOption, setPagePricingOption] = useState(null);
  const [productCharge, setProductCharge] = useState(null);
  const [optionalCharges, setOptionalCharges] = useState(null);
  
  // PRODUCT VERIFICATION:
  const [cartReady, setCartReady] = useState(false);
  const [isShell, setIsShell] = useState(null);
  const [productApproval, setProductApproval] = useState(false);
  const [subProducts, setSubProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [missingReqValues, setMissingReqValues] = useState(null);
  const [qtyAllowed, setQtyAllowed] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [inCart, setInCart] = useState(false);
  
  const [formActive, setFormActive] = useState(null);
  const [baseQuantity, setBaseQuantity] = useState(null);
  const [maxQty, setMaxQty] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [qtyAlert, setQtyAlert] = useState(null);
  const [nicknameActive, setNicknameActive] = useState(null);
  
  const formName = 'productFieldValues';
  
  // HOOKS
  useEffect(() => {
  if(props.product && (product !== props.product || inCart)) {
    setProduct(props.product);
  }else if(!props.store.product && product) {
    setProduct(null);
  }
  }, [props.product, inCart]);
  // THERE WAS A HOOK INCLUDED ABOVE FOR props.form - NEED TO VERIFY FUNCTIONALITY WITHOUT
  
  useEffect(() => {
  if(props.restriction && restriction !== props.restriction) {
    setRestriction(props.restriction);
  }else if (!props.restriction && restriction){
    setRestriction(null);
  }
  }, [props.restriction]);
  
  useEffect(() => {
  if(inCart) {
    showSuccess();
    setInCart(null);
  }
  }, [inCart]);
  
  useEffect(() => {
  if(props.languageCode && props.languageCode !== languageCode) {
    setLanguageCode(props.languageCode);
  }else if(!languageCode) {
    setLanguageCode('en');
  }
  }, [props.languageCode]);
  
  useEffect(() => {
  if(props.isShell && !isShell) {
    setIsShell(true);
  }else if(!props.isShell && isShell){
    setIsShell(false);
  }
  }, [props.isShell]);
  
  useEffect(() => {
  if(props.productApproval && 
    props.productApproval !== productApproval) {
    setProductApproval(props.productApproval);
  }
  }, [props.productApproval]);
  
  useEffect(() => {
  // NEED TO EXPAND ON THIS IN THE EVENT PRODUCT IS INVENTORY CONTROLLED BUT OUT OF STOCK
  if(product && languageCode) {
    let productStock = props.objGetValue(product, `details.${languageCode}.product_stock`);
    let productMax = props.objGetValue(product, `details.${languageCode}.product_quantity_max`);
    let restrictionAvailable = props.objExists(restriction, `available_quantity`) ? props.objGetValue(restriction, `available_quantity`) : 0;
    let cartProductQty = 0;
    if(props.objGetValue(props.cart, `items`) && Array.isArray(props.cart.items) && props.cart.items.length > 0) {
      let cartProducts = props.cart.items.filter(prod => prod.product_id === product.product_id);
      cartProducts && Array.isArray(cartProducts) && cartProducts.length > 0 && cartProducts.map((prod, index) => {
        cartProductQty += parseInt(prod.product_quantity);
      })
    } else if(props.objGetValue(props.cart, `project`)) {
      if(props.objGetValue(props.cart.project, `orders`) && Array.isArray(props.cart.project.orders) && props.cart.project.orders.length > 0) {
        props.cart.project.orders.map((order, index) => {
          if(props.objGetValue(order, `items`) && Array.isArray(order.items) && order.items.length > 0) {
            order.items.map((item, index) => {
              if(item.product_id === product.product_id) {
                cartProductQty += parseInt(item.product_quantity);
              }
            })
          }
        })
      }
    }
    if(cartProductQty > 0) {
      productStock -= cartProductQty;
      restrictionAvailable -= cartProductQty;
    }
    setProductStock(productStock);
    if(restrictionAvailable > 0) {
      setRestrictionQtyAvailable(restrictionAvailable);
    }
    if(productMax > 0) {
      if(cartProductQty > 0) {
        productMax -= cartProductQty;
      }
      if(productStock < productMax) {
        setMaxQty(productStock);
      }else{
        setMaxQty(productMax);
      }
    }else if(props.objGetValue(product, `details.${languageCode}.product_inventoried`) === 1 && 
      productStock >= 0 &&
      productStock !== maxQty) {
        setMaxQty(productStock);
    }
  }
  }, [product, languageCode]);
  
  useEffect(() => {
  if(formActive && languageCode &&
    props.objExists(product, `details.${languageCode}.fieldsets`)) {
    props.setModal(<FormDynamic {...props}
            content={product.details[languageCode].fieldsets}
            name="productFieldValues"
            formActions={{formCancel: ()=>{setFormActive(false)}, formSubmit: handleCustomize}}
            returnObject={true}
            closable={true}
            formClass="form-multi-step"
            valActions={{isEmail: props.isEmail}}
            formValues={props.objExists(props.form, `tmpFormData.${formName}`) ? props.form.tmpFormData[formName] : null}
             />);
  }else{
    props.setModal(null);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formActive, product, languageCode, props.files, props.form, props.store]);
  
  useEffect(() => {
  if (props.pricing && Array.isArray(props.pricing) && props.pricing.length > 0) {
    props.pricing.map((pricing, index) => {
    if (pricing.pricing_ctl_qty === 1 || pricing.pricing_ctl_qty === true || pricing.pricing_ctl_qty === 'true') {
      setPricingCtlQty(true);
      index === 0 && setQty(pricing.pricing_min_quantity);
    }
    });
  }
  }, [props.pricing]);
  
  // SETTING BASE QTY BASED ON PRICING OPTION
  useEffect(() => {
  if(pricingOption >= 0 && 
    props.pricing && 
    Array.isArray(props.pricing) && 
    props.pricing.length > 0 && 
    props.pricing[pricingOption] && 
    props.objExists(props.pricing, `${pricingOption}.pricing_min_quantity`) &&
    parseInt(props.pricing[pricingOption].pricing_min_quantity) !== baseQuantity) {
    setBaseQuantity(parseInt(props.pricing[pricingOption].pricing_min_quantity));
    pricingCtlQty && pricingCtlQty === true && setQty(props.pricing[pricingOption].pricing_min_quantity);
  }else if(!baseQuantity) {
    setBaseQuantity(1);
  }
  }, [pricingOption]);
  
  useEffect(() => {
  if(product && languageCode) {
    if(props.objExists(product, `details.${languageCode}.page_pricing`)) {
      setPagePricingOption(product.details[languageCode].page_pricing);
    } else {
    setPagePricingOption(null);
    }
  }
  }, [product, languageCode]);
  
  useEffect(() => {
  if(props.site && product && languageCode) {
    if(props.objExists(props.site, `persona.charges.product[0].charge_value`)) {
      setProductCharge(props.site.persona.charges.product[0].charge_value);
    }
    let optionalCharge = 0;
    let productCharge = 0;
    if(optionalCharges && Array.isArray(optionalCharges) && optionalCharges.length > 0){
      optionalCharges.map((charge, index) => {
        if (charge.charge_multiply === 1 || charge.charge_multiply === true || charge.charge_multiply === 'true') {
          optionalCharge += parseFloat(charge.charge_value) * qty;
        } else {
          optionalCharge += parseFloat(charge.charge_value);
        }
      })
    }
    if(props.objExists(product, `details.${languageCode}.charges`) && Array.isArray(product.details[languageCode].charges) && product.details[languageCode].charges.length > 0) {
      product.details[languageCode].charges.map((charge, index) => {
        if (charge.charge_multiply === 1 || charge.charge_multiply === true || charge.charge_multiply === 'true') {
          productCharge += parseFloat(charge.charge_value) * qty;
        } else {
          productCharge += parseFloat(charge.charge_value);
        }
      })
    }
    productCharge += optionalCharge;
    setProductCharge(productCharge);
  }
  }, [props.site, product, languageCode, optionalCharges, qty]);
  
  // SETTING MAX QTY
  useEffect(() => {
  if(!isNaN(parseInt(maxQty)) && maxQty >= 0 && qty > maxQty) {
    setQtyAllowed(false);
  }else{
    setQtyAllowed(true);
  }
  }, [maxQty, qty]);
  
  useEffect(() => {
  if(product && languageCode) {
    if(props.objExists(product, `details.${languageCode}.products`) &&
      Array.isArray(product.details[languageCode].products) &&
      product.details[languageCode].products.length > 0) {
      setSubProducts(true);
      if(props.objExists(product, 'selected_products') && 
        Array.isArray(product.selected_products) &&
        product.selected_products.length > 0) {
        setSelectedProducts(product.selected_products.length);
      }else{
        setSelectedProducts(null);
      }
    }
    
    if(props.objExists(product, `details.${languageCode}.fieldsets`) && 
      Array.isArray(product.details[languageCode].fieldsets) &&
      product.details[languageCode].fieldsets.length > 0) {
      setMissingReqValues(testRequiredFields(product.details[languageCode].fieldsets, 
                props.objExists(props, `form.tmpFormData.${[formName]}`) ? 
                props.form.tmpFormData[formName] : null));
      } 
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, languageCode, props.form, props.store]);
  
  useEffect(() => {
  let cartTest = true;
  if(subProducts && selectedProducts <= 0) {
    cartTest = false;
  }
  
  if(missingReqValues > 0) {
    cartTest = false;
  }
  
  if(cartTest && qty <= 0 && qtyAllowed) {
    cartTest = false;
  }
  
  if(cartTest && !qtyAllowed) {
    cartTest = false;
  }
  
  setCartReady(cartTest);
  
  }, [subProducts, selectedProducts, missingReqValues, qty, qtyAllowed]);
  
  useEffect(() => {
  if(props.pageCount && 
    props.pageCount !== pageCount) {
    setPageCount(props.pageCount);
  }
  }, [props.pageCount]);
  
  useEffect(() => {
  if(props.optionalCharges && 
    props.optionalCharges !== optionalCharges) {
    setOptionalCharges(props.optionalCharges);
  }
  }, [props.optionalCharges]);

  // FUNCTIONS
  function handleCustomize(values) {
  setFormActive(false);
  if(values && Array.isArray(values) &&
    values.length > 0) {
    let formValues = {};
    values.map(value => {
      if(value.name !== 'field_name' &&
        value.name !== 'field_value' &&
        value.name !== '' &&
        value.name !== 'form-type' && 
        value.name !== 'file-input') {
      formValues[value.name] = value.value;  
      }
    })
    
    props.formSetValue(formName, formValues); 
  }
  }
  
  function testRequiredFields(fieldsets, values) {
  if(fieldsets && 
    Array.isArray(fieldsets) &&
    fieldsets.length > 0) {
      let missingValues = 0;
      fieldsets.map((fieldset, index) => {
      if(fieldset.fields && 
        Array.isArray(fieldset.fields) &&
        fieldset.fields.length > 0) {
          fieldset.fields.map(field => {
          if(parseInt(field.field_required) === 1) {
            if(!values || !values[field.field_id] || values[field.field_id] === null) {
            missingValues++;
            }
          }
          })
        }
      })
      return missingValues;
  }else{
    return 0;
  }
  }

  function cartFindDuplicateProduct(product) {
  let duplicate = false;

  if(props.objExists(props.cart, 'items') && props.cart.items.length > 0) {
    props.cart.items.map((item, index) => {
    if(item.product_id === product.product_id) {
      if((item.field_values && product.field_values && Object.keys(item.field_values).length === Object.keys(product.field_values).length)) {
      let fieldMatch = true;
      Object.keys(item.field_values).map( field => {
        if(item.field_values[field] !== product.field_values[field]) {
        fieldMatch = false;
        }

      });
      if(fieldMatch) {
        duplicate = {location: 'cart', position: index};
      }
      }else if (!item.field_values && !product.field_values ){
      duplicate = {location: 'cart', position: index};
      }
    }
    })
  }
  if(props.objExists(props.cart, 'project.orders') && props.cart.project.orders.length > 0) {
    props.cart.project.orders.map((order, order_index) => {
    if(order.items && order.items.length > 0) {
      order.items.map( (item, item_index) => {
      if(item.product_id === product.product_id && item.field_values === product.field_values) {
        duplicate = {location: order_index, position: item_index};
      }
      })
    }
    })
  }
  return duplicate;
  }

  function mergeItem(params) {
  if(params.value === 'merge') {
    props.cartMergeItem(params.product, params.duplicate, params.value);
    setInCart(true);
  }else{
    props.cartAddDuplicateItem(params.product, params.duplicate, params.value);
    setInCart(true);
  }
  }

  function addToCart(e) {
  let cartItems = [];
  
  if(props.objExists(props.cart, 'items') && 
    Array.isArray(props.cart.items)) {
    cartItems.push(...props.cart.items);  
  }
  
  let orderItems = [];
  if(props.objExists(props.cart, 'project.orders[0].items') && 
    Array.isArray(props.cart.project.orders[0].items) &&
    props.cart.project.orders[0].items.length > 0) {
    props.cart.project.orders[0].items.map(item => {
    orderItems.push({...item});  
    })
  }
  
  let cartProduct = {...product};
  
  if (props.objExists(cartProduct.details[languageCode], 'product_end') ) {
    cartProduct.details[languageCode].product_name += " (Expires: " + props.dateDisplay(cartProduct.details[languageCode].product_end) + ")";
  }
  cartProduct['product_quantity'] = qty;
  if(props.objExists(props, 'pricing') && 
    Array(props.pricing) && 
    props.pricing.length > 0) {
    cartProduct[['product_pricing_id']] = props.pricing[pricingOption].pricing_id;
    cartProduct['product_pricing_option'] = props.pricing[pricingOption].pricing_option;
    cartProduct['product_price'] = subtotal;
  }
  if (props.objExists(product, `details.${languageCode}.page_pricing`)) {
    cartProduct['page_count'] = pageCount;
    cartProduct['page_pricing_option'] = pagePricingOption;
  }
  cartProduct['language_id'] = props.getLanguageId(languageCode);
  cartProduct['language_code'] = languageCode;

  let duplicate;  
  
  // if(props.objExists(product, 'selected_products') &&
  //     Array.isArray(product.selected_products) && 
  //     product.selected_products.length > 0 ) {
  //   cartProduct['selected_products'] = product.selected_products;      
  // }
  if(props.customizable && props.form.tmpFormData && props.form.tmpFormData[formName]) {
    cartProduct['field_values'] = props.form.tmpFormData[formName];
  }
  // duplicate = cartFindDuplicateProduct(cartProduct);
  if(!duplicate && 
    (!props.objExists(props.cart, 'project.orders') ||
    props.objExists(props.cart, 'project.orders') ||
    (Array.isArray(props.cart.orders) && 
      (props.cart.orders.length === 0 || props.cart.orders.length > 1))) ) {
    
    cartItems.push(cartProduct);
    cartItems.length > 0 &&
    props.cartAssignValue('items', cartItems);
    // showSuccess();
    // props.notifyUser(
    //   {icon: 'cart',
    //     message: `<b>${props.capitalizeString(cartProduct.details[languageCode].product_name, true)}</b><br />
    //               ${qty} ${parseInt(qty) === 1 ? 'has' : 'have'} been added to your cart.`
    //   }
    // );
    // props.cartAddItem(product, 'cart');
    setInCart(true);
  }else if (!duplicate && props.objExists(props.cart, 'project.orders') && 
        Array.isArray(props.cart.project.orders) &&
        props.cart.project.orders.length === 1){
    orderItems.push(cartProduct);
    props.cartSetValue('project.orders[0].items', orderItems);
    // props.notifyUser(
    //   {icon: 'cart',
    //     message: `<b>${props.capitalizeString(cartProduct.details[languageCode].product_name, true)}</b><br />
    //               ${qty} ${parseInt(qty) === 1 ? 'has' : 'have'} been added to your order`
    //   }
    // );
    setInCart(true);
  }else if(duplicate) {
//       console.log(" Duplicate");
//       let options = [];
//       options.push({label: `Merge with existing item`, value: 'merge', group: 'itemMerge'});
//       if(props.objExists(props.cart, 'project.orders') && props.cart.project.orders.length > 0) {
//         options.push({label: `Add as separate cart item (assign to shipment later)`,
//                       value: 'cart',
//                       group: 'itemMerge'});
//         props.cart.project.orders.map((order, index) => {
//           return options.push({label: `Add to order ${index+1}: ships to ${order.shipping.location.location_address_1}`,
//                                 value: index,
//                                 group: 'itemMerge'});
//         })
//       }else{
//         options.push({label: `Add as separate item`,
//                       value: 'cart',
//                       group: 'itemMerge'});
//       }
// 
//       props.setModal(<FormDialogBox
//                         dialog="This item is already in your cart, would you like to combine quantities as a single item or add it a second time?"
//                         options={{type: 'radio', options: options, group: 'itemMerge'}}
//                         buttons={[{label: 'Cancel', btnClass: 'error-bg', action: null},
//                                 {label: 'Proceed', btnClass: 'success-bg', action: mergeItem, actionParams: {product, duplicate}, btnValidate: 'itemMerge'}]}
//                         actionClose={()=>{props.setModal(null)}}
//                         />
//                     )

  }else{
    e.preventDefault();
  }
  }

  function updateQty(value, alertMsg = null) {
  if(alertMsg) {
    setQtyAlert(alertMsg);
  }else{
    setQtyAlert(null);
  }
  let thisQty = isNaN(value) || value === null ? null : props.numberDisplay(value);
  setQty(thisQty);
  }

  function formatNumber(num) {
  num = num.replace(/\D/g,'');
  return num;
  }

 function handlePriceOption(e) {
  if((e.value) && e.value !== '' ) {
    setPricingOption(parseInt(e.value));
  }
  }
  
  function updateSubtotal(stAmount) {
  if(stAmount !== subtotal) {
    setSubtotal(stAmount);
  }
  }
  
  function addNickname(param) {
  const value = param.value;
  if(typeof value === 'string') {
    if(value === '' || !value) {
    props.storeDeleteVal(param.name);
    // props.storeSetVal(param.name, 'bbbbbb');
    }else{
    props.storeSetVal(param.name, value);  
    }
  }
  }
  
  function showSuccess() {
  props.setModal(
    <CartAddSuccess
    {...props}
    product={product}
    productQty={qty}
    handleSelect={handleSuccessSelect}
    />,
    false,
    false
  );
  }
  
  function handleSuccessSelect() {
  if(props.handleCartAdd) {
    props.handleCartAdd();
  }
  props.objExists(props.form, `tmpFormData.${formName}`) && props.clearFormInfo(formName);
  }

  return (
  <div className="product-details-ctl">
    {languageCode && product && props.objExists(product, `details.${languageCode}.fieldsets`) && 
    <Fragment>
      {props.customizable && props.useModal ?
      <div className="form-row">
      <Button type="button"
          label={props.form.tmpFormData && props.form.tmpFormData[formName] ? 'Edit' : 'Customize'}
          icon="edit"
          btnClass="info-bg"
          btnAction={!formActive ? ()=>{setFormActive(true)} : null} />
      </div>
      :
      props.customizable && !props.useModal &&
      <FormDynamic 
        content={product.details[languageCode].fieldsets}
        name="productFieldValues"
        formActions={null}
        closable={true}
        unmountOnClick={true}
        formClass="form-multi-step"
        valActions={{isEmail: props.isEmail}}
        formValues={props.objExists(props, 'form.tmpFormData.productFieldValues') ? props.form.tmpFormData.productFieldValues : null} />
    }
    </Fragment>
    }
    
    {Array.isArray(props.pricing) && props.pricing.length > 1 &&
    <div className="form-row">
      <Select name="pricing_option"
          label={pricingCtlQty === true ? 'Quantity' : 'Product Option'}
          defaultValue={pricingOption}
          onChange={handlePriceOption}
          required="true"
          option_groups = {[{options:
          props.pricing && Array.isArray(props.pricing) &&
          props.pricing.map((price, index) => {
            return {option_name: price.pricing_option, option_value: index.toString()};
          })
          }]} />
    </div>
    }
    {priceMsg &&
    <FormMsg formMsg={priceMsg} />
    }
    <div className="product-details-ctl-qtyprice">
    <div className="form-row">
      {pricingCtlQty === false && <ProductInputQty {...props}
        onChange={updateQty}
        className="product-details-ctl-qty input-sml"
        type="number"
        name="product_quantity"
        defaultValue={props.objGetValue(product, `details.${languageCode}.product_inventoried`) && productStock < baseQuantity ? productStock : baseQuantity}
        label="Quantity"
        size="small"
        override={true}
        preventNull={false}
        minQty={0}
        maxQty={maxQty}
        isDecimal={false}
        actionOnChange={updateQty} />}
      {(props.objGetValue(product, `details.${languageCode}.product_inventoried`) || 
      props.objGetValue(product, `details.${languageCode}.product_quantity_max`) > 0) &&
        <div className="input-info">
          {props.objExists(product, `details.${languageCode}.product_inventoried`) &&
            <div className="input-info-note">
            {`${productStock > 0 ? productStock : 0}
            ${props.objGetValue(product, `details.${languageCode}.product_bundle`) > 0 ? ' bundles' : ''} available.`}
            </div>
          }
          {restriction &&
            <div className="input-info-alert margin-top-1em">
            <IconSVG icon="alert" />
              <div>
                {`Your product limit is ${restriction.product_limit} ${restriction.restriction_days > 0 && ' every '} ${restriction.restriction_days > 0 && restriction.restriction_days} ${restriction.restriction_days > 0 ? ' days.' : '.'}`}<br />
                You have <b>{restrictionQtyAvailable > 0 ? restrictionQtyAvailable : '0'}</b> remaining.<br />
                Approval is required to order more.
              </div>
            </div> 
          }
          {qtyAlert &&
            <div className="input-info-alert margin-top-1em">
              <IconSVG icon="alert" />
              <div>{qtyAlert}</div>
            </div>  
          }
        </div>
      }
    </div>
    <ProductPrice {...props}
      
      languageCode={languageCode}
      qty={qty}
      restriction={restriction}
      pricingOption={pricingOption}
      pageCount={pageCount}
      pagePricingOption={pagePricingOption}
      productCharge={productCharge}
      hidePricing={props.hidePricing}
      actionUpdateSubtotal={updateSubtotal}
      actionPriceMsg={setPriceMsg} />
    </div>
    {!props.hideLabel &&
    <div className={nicknameActive ? 'active' : '' }>
      <Control {...props} 
          icon={nicknameActive ? 'close' : 'label'} 
          label={nicknameActive ? `Hide label field` : 
              props.objExists(props.store, `product.product_nickname`) ? 'Change item label' : 
              `Add a label?`} 
          showLabel={true}
          action={()=>{setNicknameActive(nicknameActive ? false : true)}} />
      <div className={`form-row expandable${nicknameActive ? ' active' : ''}`} style={{maxWidth: '15em'}}>
      <Input {...props}
        name="product.product_nickname"
        value={props.objExists(props.store, `product.product_nickname`) ? 
            props.store.product.product_nickname : ''}
        label="Item Label"
        placeholder="Add an item label"
        onChange={addNickname} />
      </div>
    </div> 
    }
    <div className="form-row">
    <Button type="button"
      label={product && product.details[languageCode].product_inventoried === 1 && productStock <= 0 ? "Out of stock":"Add to cart"}
      icon="cart"
      btnClass={(!isShell || (isShell && productApproval) || (isShell && product.details[languageCode].fieldsets.length == 0)) && cartReady ? "success-bg" : "grey-bg"}
      disabled={(!isShell || (isShell && productApproval) || (isShell && product.details[languageCode].fieldsets.length == 0)) && cartReady && product &&
          (product.details[languageCode].product_inventoried === 0 || productStock > 0) ? 
            false : true}
      btnAction={addToCart} />
    </div>
    {/*props.objExists(props.cart, 'items') && props.cart.items.length > 0 && 
    <div className="form-row">
      <Link to="/cart"><Button label="View Cart" icon="cart" btnClass="info-bg mini" /></Link>
    </div>
    */}
    
    {/* <div className="form-row">
    <Button label="Show success" icon="cart" btnClass="info-bg mini" btnAction={showSuccess} />
    </div> */}
  </div>
  )
}
