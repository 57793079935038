import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';
import FormTaskAssign from 'components/forms/common_forms/FormTaskAssign';
import Loader from 'components/atoms/Loader';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [isAdmin, setIsAdmin] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [activeUser, setActiveUser] = useState(null);
	const [isUpdating, setIsUpdating] = useState(null);
	
	// HOOKS
	useEffect(() => {
		props.userID && 
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token && 
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID && 
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	useEffect(() => {
		props.languageCode && 
		props.languageCode !== languageCode &&
		setLanguageCode(props.languageCode);
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.isAdmin && !isAdmin) {
		  setIsAdmin(true);
		}else if(!props.isAdmin && isAdmin) {
		  setIsAdmin(false);
		}
	}, [props.isAdmin]);
	
	useEffect(() => {
		props.userAccess &&
		props.userAccess !== userAccess &&
		setUserAccess(props.userAccess);
	}, [props.userAccess]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	  useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(props.objExists(props.task, 'users.owner.user_id')) {
			setActiveUser({
				initials: `${props.task.users.owner.contact_name_first[0]}${props.task.users.owner.contact_name_last[0]}`,
				name: `${props.task.users.owner.contact_name_first} ${props.task.users.owner.contact_name_last}`,
			});
		}
		if(isUpdating) {
			setIsUpdating(false);
		}
	}, [props.task]);
	
	function loadAssignTask() {
		props.setModal(
			<FormTaskAssign {...props}
							userID={userID}
							token={token}
							organizationID={organizationID}
							languageCode={languageCode}
							location={props.location}
							source={props.source}
							canEdit={canEdit}
							isSuper={isSuper}
							task={props.task}
							actionComplete={assignTaskComplete}
							users={props.objExists(props.admin, 'staff.users') ? props.admin.staff.users : null}
							groups={props.objExists(props.admin, 'staff.groups') ? props.admin.staff.groups : null} />
		);
	}
	
	function assignTaskComplete() {
		
		setIsUpdating(true);
		props.setModal(null);
	}
	
	// console.log("USER ACCESS", userAccess);
	// console.log("TASK GENERAL", props.userAccess, props.task);
	
	return (
		props.task &&
			<div className={`task-general${props.className ? ` ${props.className}` : ''}`}>
				<div className="task-general-info">
					<h4>Current Activity</h4>
					<div className="status">
						{isUpdating ? 
							<Loader label="Updating..." />
						:
							<Fragment>
								<div>{props.capitalizeString(props.task.task_name, true)}</div>
								<div>
									{props.objExists(props.task, `status[0].${languageCode}.status_name`) &&
										props.task.status[0][languageCode].status_name !== 'new' ?
										props.capitalizeString(props.task.status[0][languageCode].status_name, true) : 
										'Pending'
									}
								</div>
							</Fragment>
						}
					</div>
					<div className="non-minified">
						{Array.isArray(props.objGetValue(props.task, `users.groups`)) &&
							<div className="flex flex-wrap">	
								<IconSVG icon="group" />
								{props.task.users.groups.map((group, gIndex, groups) => {
									return (
										<span key={`tskusrgrps${gIndex}`}>
											{`${group.group_name}${gIndex+1 < groups.length ? ', ' : ''}`}
										</span>
									)
								})}
							</div>
						}
						{Array.isArray(props.objGetValue(props.task, `users.linked`)) &&
							<div className="flex flex-wrap">	
								<IconSVG icon="user" />
								{props.task.users.linked.map((user, uIndex, users) => {
									return (
										<span key={`tskgnrlusr${uIndex}`}>
											{`${user.contact_name_first} ${user.contact_name_last}${uIndex+1 < users.length ?
												', ' : ''}`}
										</span>
									)
								})}
							</div>
						}
					</div>
				</div>
				<div className="task-general-ctl non-minified">
					{isAdmin &&
						<Fragment>
							{!props.task.task_owner ?
								<Fragment>
								{userAccess && 
								(userAccess.super || 
								(userAccess.staffAdmin && userAccess.staffAdmin[organizationID])) ?
									<IconToken icon="user-add" 
												action={loadAssignTask}
												tooltip="Assign Task" />
								:
									<div className="user-badge">
										<div className="tooltip">No User</div>
										?
									</div>	
								}
								</Fragment>
							:
								<div className="user-badge">
									<div className="tooltip">{activeUser && activeUser.name ? activeUser.name : 'Unavailable'}</div>
									{activeUser && activeUser.initials ? activeUser.initials : ''}
								</div>
							}
						</Fragment>
					}
				</div>
			</div>
		
	)
}