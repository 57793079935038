import React, {useEffect, useState} from 'react';

import OrderListItem from 'components/profiles/orders/OrderListItem';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [coreUser, setCoreUser] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [orders, setOrders] = useState(null);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.coreUser && props.coreUser !== coreUser) {
      setCoreUser(props.coreUser);
    }
  }, [props.coreUser]);
  
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.orders && props.orders !== orders) {
      setOrders(props.orders);
    }
  }, [props.orders]);
  
  return (
    orders &&
    <div className="dashboard-widget">
      <div className="widget-header">
        <h3>Order History</h3>
        <div>{`${orders.length > 0 ? orders.length : `No`} Order${orders.length === 1 ? '' : 's'}`}</div>
      </div>
      <div className="scrollbox">
        <ul>
          {Array.isArray(orders) && 
            orders.map((project, index) => {
            if(organizationID === project.organization_id ||
              organizationID === 1) {
                return <OrderListItem {...props} 
                            order={project} 
                            location={props.location}
                            key={`ordlst${index}`}
                            organizationID={props.organizationID}
                            languageCode={props.languageCode} />  
              }
          })}
        </ul>
      </div>
    </div>
  )
}
