import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

import * as formData from 'methods/forms.js';

export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	const [location, setLocation] = useState(null);
	const [locations, setLocations] = useState(null);
	const [items, setItems] = useState(null);
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [countryName, setCountryName] = useState('CA');
	const [countryOptions, setCountryOptions] = useState(null);
	const [provinceOptions, setProvinceOptions] = useState(null);
	const [provinceLabel, setProvinceLabel] = useState('Province');
	const [postalCodeLabel, setPostalCodeLabel] = useState('Postal Code');
	
	// HOOKS
	useEffect(() => {
		if(props.languageCode && props.langaugeCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(props.objExists(props.site, 'language_code') && 
				props.site.langauge_code !== languageCode) {
			setLanguageCode(props.site.languageCode);
		}else if(!languageCode) {
			setLanguageCode('en');
		}
	}, [props.langaugeCode]);
	
	useEffect(() => {
		if(props.location && !location) {
			setLocation(props.location);
		}
	}, [props.location]);
	
	useEffect(() => {
		if(props.locations && !locations) {
			setLocations(props.locations);
		}
	}, [props.locations]);
	
	useEffect(() => {
		if(props.items && props.items !== items) {
			setItems(props.items);
		}
	}, [props.items]);
	
	useEffect(() => {
		if(!location && form) {
			const formInputs = document.getElementById('modal').querySelectorAll('input');
			formInputs.forEach(input => {
				if(input.name !== 'form-type') {
					console.log("Clearing the value for ", input.name);
					input.value = '';
				}
			})
		}
	}, [location]);
	
	useEffect(() => {
		let locationFields = formData.fieldsLocation;
		let fields = [];
		
		if(props.showQtyList && items && languageCode) {
			let optionsItems = [];
			items.map((item, index) => {
				optionsItems.push({
					option_label: item.details[languageCode].product_name,
					option_name: `items.${index}`,
					option_value: 1
				})
			});
			fields.push({
				field_type: 'qtylist',
				field_name: 'items',
				field_label: 'Include these items with shipment',
				fieldOnChange: nullFunction,
				option_groups: [{
					options: optionsItems
				}] 
			});
		}
		
		if(locations && Array.isArray(locations) && 
			(locations.length > 1 || (location && locations[0].location_id !== location.location_id))) {
				
			let locOptions = [{option_name: 'Your locations...', option_value: null}];
			locations.map(loc => {
				locOptions.push({
					option_name: `${loc.location_name ? `${loc.location_name} ` : ''}${
									loc.location_address_1}, ${loc.location_city}`,
					option_value: loc.location_id,
				})
			});
			locOptions.push({option_name: 'New Location', option_value: 'clear'});
				
			fields.push(
				{field_type: 'select',
				field_name: 'location',
				field_label: 'Your Locations',
				field_value: location && location.location_id ? location.location_id : null,
				fieldOnChange: loadLocation,
				field_required: false,
				field_validate: false,
				option_groups: [{options: locOptions}],
				}
			);		
		}
		
		locationFields.map((field, index) => {
			if(location && field.field_name === 'location_default') {
				locationFields[index].field_value = (location.location_user_default && 
													location.location_user_default	=== 1) ||
													(location.location_org_default && 
													location.location_org_default === 1) ||
													(location.location_default &&
													location.location_default === 1) 		?
															true : false;
			}else if(location && location[field.field_name] !== null) {
				locationFields[index].field_value = location[field.field_name];
			}else{
				locationFields[index].field_value = null;
			}
			// Replace location_country and location_province options lists
			if(field && field.field_name === 'location_country') {
				field.fieldOnChange = changeCountry;
				field.option_groups[0].options = countryOptions;
				if (countryName) {
					field.field_value = countryName;
				}
				
			} else if (field && field.field_name === 'location_province') {
				field.field_label = provinceLabel;
				if (provinceOptions !== field.option_groups[0].options) {
					field.option_groups[0].options = provinceOptions;	
				}
				if (countryName) {
					let country = props.site.persona.countries.find(country => {
				  		return country.country_name === countryName
					})
					if (country) {
						let province = country.provinces.find(province => {
							  return province.province_name === field.field_value
						})
						if (!province) {
							field.field_value = null;
						}
					}
				}
			} else if (field && field.field_name === 'location_postal_code') {
				field.field_label = postalCodeLabel;
			}
		})
		
		fields.push(...locationFields);
		
		fields.push({field_type: 'hidden', field_name: 'process', 
					field_value: location && location.location_id ? 'update_location' : 'add_location'});
		
		props.userID && props.token &&
		fields.push(
			{field_type: 'hidden', field_name: 'user_id', field_value: props.userID},
			{field_type: 'hidden', field_name: 'token', field_value: props.token},
		);
		
		props.coreUser &&
		fields.push(
			{field_type: 'hidden', field_name: 'core_user', field_value: props.coreUser}
		);
		
		props.organizationID &&
		fields.push(
			{field_type: 'hidden', field_name: 'organization_id', field_value: props.organizationID}
		);
		
		if(props.locationType && props.locationLink) {
			fields.push(
				{field_type: 'hidden', field_name: 'location_type', field_value: props.locationType},
				{field_type: 'hidden', field_name: 'location_link', field_value: props.locationLink}
			);
		}
		
		props.returnBool &&
		fields.push(
			{field_type: 'hidden', field_name: 'return_bool', field_value: 'true'}
		);
		
		if(location && location.location_id) {
			fields.push({
				field_type: 'hidden',
				field_name: 'location_id',
				field_value: location.location_id,
				field_required: true,
				field_validate: false,
			})
		}
		
		setFieldsets(
			[
				{fieldset_name: `${location && location.location_id ? 'Edit' : 'Add New'} Location`,
				  fields: fields
			  }
			])
	}, [locations, location, countryOptions, countryName, provinceOptions]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name={`${location && location.location_id ? 'edit' : 'add'}-location`}
				  formCompleteLabel={location && location.location_id ? `Update` : `Add`}
				  returnObject={true}
				  fieldOnChange={null}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets, location]);
	
	useEffect(() => {
		if (Array.isArray(props.site.persona.countries)) {
			// setCountryOptions
			let countryOptions = [];
			props.site.persona.countries.map((country, index) => {
				countryOptions.push({option_name: country.country_name_full, option_value: country.country_name});
			})
			setCountryOptions(countryOptions);
			// setCountryName
			let country = [];
			if (location) {
				country = props.site.persona.countries.find(country => {
				  return country.country_name === location.location_country
				})
			} else {
				country = props.site.persona.countries.find(country => {
				  return country.country_default === 1
				})
			}
			if (country) {
				setCountryName(country.country_name);
				if (country.country_name === 'US') {
					setProvinceLabel('State');
					setPostalCodeLabel('Zip Code');
				} else {
					setProvinceLabel('Province');
					setPostalCodeLabel('Postal Code');
				}
				if(Array.isArray(country.provinces)) {
					let provinceOptions = [];
					country.provinces.map((province, index) => {
						provinceOptions.push({option_name: province.province_name_full, option_value: province.province_name});
					});
					setProvinceOptions(provinceOptions);
				}
			}
		}
	}, [location]);
	
	useEffect(() => {
		if (countryName) {
			let country = props.site.persona.countries.find(country => {
			  return country.country_name === countryName
			})
			if (country) {
				if (country.country_name === 'US') {
					setProvinceLabel('State');
					setPostalCodeLabel('Zip Code');
				} else {
					setProvinceLabel('Province');
					setPostalCodeLabel('Postal Code');
				}
				if(Array.isArray(country.provinces)) {
					let provinceOptions = [];
					country.provinces.map((province, index) => {
						provinceOptions.push({option_name: province.province_name_full, option_value: province.province_name});
					});
					setProvinceOptions(provinceOptions);
				}
			}
		}
	}, [countryName]);
	
	// FUNCTIONS
	function loadLocation(param) {
		if(param.value === 'clear') {
			setForm(null);
			setLocation(null);
		}else if(param.value) {
			const locationSelected = locations.find(loc => loc.location_id === parseInt(param.value));
			if(locationSelected && locationSelected !== location) {
				// setForm(null);
				setLocation(locationSelected);
			}
		}
	}
	
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel();
		}
	}
	
	function handleAddEdit(params) {
		if(props.actionAddEdit) {
			props.actionAddEdit(params, props.actionParams ? props.actionParams : null);	
		}
	}
	
	function changeCountry(param) {
		setLocation({...location, location_province: null, location_postal_code: null, location_country: param.value});
		setCountryName(param.value);
		
	}
	
	function nullFunction() {
		
	}
	
	
	
	return (
		form ? form : <Loader />
	)
}