import React, {useEffect, useState, useRef, useCallback} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import InputHook from 'components/forms/elements/Input';
import IconToken from 'components/icons/IconToken';

export default (props) => {
  const [fieldValue, setFieldValue] = useState(null);
  const [fieldValid, setFieldValid] = useState(false);
  const [initialValidate, setInitialValidate] = useState(null);
  const [activeListener, setActiveListener] = useState(false);
  const [originalValue, setOriginalValue] = useState(null);
  const [keyAccept, setKeyAccept] = useState(null);
  const inputRef = useRef()

  useEffect(() => {
    setFieldValue(props.field_value ? props.field_value : props.field_default ? props.field_default : null);

  }, [])

  useEffect(() => {
    if(props.field_actions) {
      props.field_actions.validate &&
      setFieldValid(props.field_actions.validate(fieldValue));
      setInitialValidate(true);
    }
  }, [fieldValue]);

  useEffect(() => {
    if(props.active && !activeListener) {
      document.addEventListener('keydown', handleKey);
      setActiveListener(true);
    }
    return () => {
      document.removeEventListener('keydown', handleKey);
      setActiveListener(false);
    }
  }, [props.active])

  // WE NEED TO USE THIS METHOD ON THE EVENT LISTENER BECAUSE IT DOESN'T CAPTURE THE fieldValue AT THE CURRENT STATE
  useEffect(() => {
    if(keyAccept === true) {
      acceptUpdate();
      setKeyAccept(false);
    }
  }, [keyAccept]);

  function handleKey(e) {
    if(e.key === 'Enter' && document.activeElement.name === props.field_name) {
      e.preventDefault();
      // WE SET THIS STATE VALUE TO TRIGGER useEffect TO RUN ACCEPT UPDATE
      setKeyAccept(true);
    }
  };

  function handleClick(e) {
    e.preventDefault();
    props.actions.activate(props.index);
  }

  function updateField(e) {
    props.actions.updateField &&
    props.actions.updateField(e);
  }

  function updateValue(field) {
    setFieldValue(field.value);
    props.actions.updateValue &&
    props.actions.updateValue(field);

    if(props.field_actions) {
      props.field_actions.input &&
      props.field_actions.input(field);
      props.field_actions.validate &&
      setFieldValid(props.field_actions.validate(field.value));

      // props.field_actions.validate &&
      // console.log("VALIDATE", props.field_actions.validate, field.value, props.field_actions.validate(field.value));
    }

  }

  function cancelUpdate() {
    props.cartSetInfo(originalValue, props.field_name);
    props.actions && props.actions.cancel &&
    props.actions.cancel();
  }

  function acceptUpdate() {
    props.field_actions && props.field_actions.update &&
    props.field_actions.update(fieldValue);

    props.actions && props.actions.confirm &&
    props.actions.confirm();

    props.actions.activate('next');
  }

  function openEdit() {
    setOriginalValue(props.field_value ? props.field_value : props.field_default ? props.field_default : null);
    props.actions && props.actions.activate &&
    props.actions.activate(props.index);
  }
  // console.log("field progressive field", props.field_name, props.field_activate_accept);
  return (
    <div className={`form-row progressive-form-field${props.active ? ' active' : ''}${props.field_errors && props.field_errors.includes(props.field_name) ? ' error' : ''}${fieldValid ? ' valid' : ''}`} >
      {props.active ?
        <div className="progressive-form-field-input" >
          <InputHook type={props.field_type}
                      label={props.field_label}
                      focus={props.active ? true : false }
                      focusSelect={true}
                      updateAction={updateField}
                      onChange={updateValue}
                      name={props.field_name}
                      default={props.field_default}
                      placeholder={props.field_placeholder}
                      value={props.field_value ? props.field_value : props.field_default ? props.field_default : null}
                      inputRef={inputRef}
                      valid={fieldValid} />
          <div className="progressive-form-field-ctl">
            <IconToken icon="close" className="error-bg" action={cancelUpdate} />
            <IconToken icon="check" className="success-bg" action={acceptUpdate} disabled={props.field_activate_accept !== true ? true : false} />
          </div>
        </div>
        :
        <div className="progressive-form-field-text" onClick={openEdit} >
          <div className="progressive-form-field-text-value">
            <label>{props.field_label}</label>
            <div className="progressive-form-field-text-value-field" >
              {ReactHtmlParser(props.field_type === 'password' ? props.maskValue(props.field_value) :   props.field_value)}
              {fieldValid && <IconToken icon="check" className="success text-indicator" />}
            </div>
          </div>
          <div className="progressive-form-field-ctl">
            <IconToken icon="edit" className="shade-bg" action={openEdit} />
          </div>
          <input type="hidden" name={props.field_name} value={fieldValue ? fieldValue : ''} />
        </div>
      }
    </div>
  )
}
