import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router';

import Loader from 'components/atoms/Loader';
import FormProductAdd from 'components/forms/common_forms/FormProductAdd';

export default (props) => {
  const [user, setUser] = useState(null);
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [productLanguage, setProductLangauge] = useState('en');
  const [productOrg, setProductOrg] = useState(null);
  const [optionsTypes, setOptionsTypes] = useState(null);
  const [optionsOrgs, setOptionsOrgs] = useState(null);
  const [productTypes, setProductTypes] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [saveReady, setSaveReady] = useState(null);
  const [saveProduct, setSaveProduct] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [isSuper, setIsSuper] = useState(false);
  const [redirect, setRedirect] = useState(null);
  
  // HOOKS
  useEffect(() => {
    return () => {
      props.adminDeleteVal('product');
    }
  }, []);
  
  useEffect(() => {
    if(props.objExists(props.user, `user_id`) &&
      props.user != user) {
      setUser(props.user);
    }
  }, [props.user]);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(organizationID > 1 && coreOrg !== organizationID) {
      setCoreOrg(organizationID);
    }else if(organizationID === 1 && 
            props.coreOrg && props.coreOrg !== coreOrg) {
      setCoreOrg(props.coreOrg);
    }else if(coreOrg && !props.coreOrg && 
              organizationID === 1) {
      setCoreOrg(null);
    }
  }, [props.coreOrg, organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.canEdit && props.canEdit !== canEdit) {
      setCanEdit(props.canEdit);
    }
  }, [props.canEdit]);
  
  useEffect(() => {
    if(props.isSuper && props.isSuper !== isSuper) {
      setIsSuper(props.isSuper);
    }
  }, [props.isSuper]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.admin, 'product_types')) && 
        props.admin.product_types !== productTypes) {
        setProductTypes(props.admin.product_types);
    }
  }, [props.admin.product_types]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'productAdded') && 
        props.admin.productAdded === true) {
          setSaveSuccess(true);
    }
    
    if(props.objExists(props.admin, 'product.organization_id') && 
      parseInt(props.admin.product.organization_id) !== productOrg) {
        setProductOrg(parseInt(props.admin.product.organization_id));
    }
    
    if(user && props.objExists(props.admin, 'organizations') &&
        props.admin.organizations !== organizations &&
        Array.isArray(props.admin.organizations)) {
          setOrganizations(props.admin.organizations);
          const orgOptions = [];
        props.admin.organizations.map(org => {
          if(org.organization_id !== 1 &&
            (user.organizations.find(uOrg => uOrg.organization_id === org.organization_id) ||
              (props.objGetValue(props.userAccess, `super`) === true || 
              props.objGetValue(props.userAccess, `1`) === true))) {
            orgOptions.push({option_name: org.organization_name, option_value: org.organization_id}); 
          }else{
            console.log("USER ORGS", user.organizations, props.userAccess);
          }
        });
        if(optionsOrgs !== orgOptions) {
          setOptionsOrgs(orgOptions);
        }
    }
  }, [props.admin, productLanguage, user]);
  
  useEffect(() => {
    if(productLanguage) {
      if((parseInt(props.objGetValue(props.admin, `product.details.${productLanguage}.type.type_id`)) > 0 ||
          props.objExists(props.admin, `product.details.${productLanguage}.type.type_name`)) &&
          props.objExists(props.admin, `product.details.${productLanguage}.product_name`) &&
          props.objExists(props.admin, `product.details.${productLanguage}.product_sku`)) {
        setSaveReady(true);
      }else if(saveReady) {
        setSaveReady(false);
      }
    }else if(saveReady) {
      setSaveReady(false);
    }
  }, [props.admin.product, productLanguage]);
  
  useEffect(() => {
    if(saveSuccess) {
      props.adminDeleteVal('product');
      
    }
  }, [saveSuccess]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'current.addProduct.product_id')) {
      const productID = props.admin.current.addProduct.product_id;
      props.adminDeleteVal('current.addProduct');
      props.adminDeleteVal('product');
      props.adminSetVal('reload.product_types');
      props.adminSetVal('reload.products');
      setSaveProduct(false);
      setRedirect(`/admin/products/product/${productID}`);
    }
  }, [props.admin.current]);
  
  // FUNCTIONS
  function fieldOnChange(param) {
    if(param.value !== null && props.objGetValue(props.admin, param.name) !== param.value) {
      props.adminSetVal(param.name, param.value);
    }
  }
  
  function addProduct(params) {
    setSaveProduct(true);
    props.adminLoadState(params, 'products', 'current.addProduct');
  }
  
  function clearProduct() {
    props.adminDeleteVal('product');
  }
  
  return(
    <Fragment>
      {redirect && <Redirect to={redirect} />}
      {saveSuccess &&
        <p>SAVE SUCCESS!!</p>
      }
    {(optionsOrgs || organizationID > 1) &&
      <FormProductAdd 
        {...props}
        productLanguage={productLanguage}
        productOrg={organizationID === 1 ? coreOrg ? coreOrg : null : organizationID}
        userID={userID}
        token={token}
        languageCode={languageCode}
        organizationID={organizationID}
        optionsOrgs={optionsOrgs}
        productTypes={productTypes}
        productLanguage={productLanguage}
        saveReady={saveReady}
        actionFieldOnChange={fieldOnChange}
        actionAdd={addProduct}
        actionClear={clearProduct}
        location={'admin'}
        cancelable={false}
      />  
    }
    
    {saveProduct && 
      <Loader className="full" 
              label="Creating product..." />}
    </Fragment>
  )
}