import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import Control from 'components/atoms/Control';

export default (props) => {
	const [contact, setContact] = useState(null);
	const [required, setRequired] = useState([]);
	
	// HOOKS
	useEffect(() => {
		if(props.contact && props.contact !== contact) {
			setContact(props.contact);
		}
	}, [props.contact]);
	
	useEffect(() => {
		if(Array.isArray(props.requiredFields)) {
			const requiredContactFields = props.requiredFields.filter(rField => rField.match('contact_'));
			if(requiredContactFields.length > 0 && requiredContactFields !== required)
			setRequired(requiredContactFields);
		}
	}, [props.requiredFields]);
	
	useEffect(() => {
		if(Array.isArray(required) && contact) {
			let missing = {};
			required.map((field, index) => {
				if(!contact[field] || 
					contact[field] === '') {
						missing[field] = true;
				}
			})
		}
	}, [required, contact]);
	
	// FUNCTIONS 
	function handleAddEdit(type, value) {
		switch (type) {
			case 'location':
				props.actionContactAddEdit &&
				props.actionContactAddEdit(type, value);
				break;
			case 'contact':
				props.actionContactAddEdit &&
				props.actionContactAddEdit(type, value);
				break;
			default:
				break;
		}
	}
	
	return (
		<Fragment>
			<div>
				<div className="flex">
					<div className="flex-col">
						{contact && <Fragment>
							<div className={`block contact auto${props.className ? ` ${props.className}` : ''}`}>
								<div className="flex-row" style={{marginLeft: '0em'}}>
									<div className="truncate" style={{flex: '0 0 4.25em'}}>
										{props.contactHeading &&
											<div className="block-heading">
												{props.contactHeading}
											</div>
										}
									</div>
									<div className="truncate" style={{flex: '1 0 1em'}}>
										<div className="block-row title">
											{contact.contact_name_first && contact.contact_name_last ?
												`${contact.contact_name_first} ${contact.contact_name_last}`
											:
												<div className="error" style={{marginBottom: '1em', fontWeight: 'normal'}}>
												{props.allowEdit ?
													<Control {...props}
														className={`ctl-btn flex-full${props.error ? ` error-bg` : ''}`}
														icon="user-add"
														label="Add Contact Information"
														showLabel={true}
														action={()=>{handleAddEdit('contact', null)}} />
												: `Information missing`
												}
												</div>	
											}
											{contact.contact_default &&
												<IconToken icon="star" tooltip="Default Contact" />
											}
										</div>
										{contact.contact_title &&
											<div className="block-row subtext">
												{contact.contact_title}
											</div>
										}
										<div className="block-row">
											<IconToken icon="email" />
											{contact.contact_email}
										</div>
										{(contact.contact_phone || 
										required.includes('contact_phone')) &&
											<div className={`block-row ${required.includes('contact_phone') ? '' : ' optional'}${!contact.contact_phone ? ' error' : ''}`}>
												<IconToken icon="phone" />
												{contact.contact_phone? contact.contact_phone : 'Missing Phone Number!'}
											</div>
										}
										{(contact.contact_mobile || 
										required.includes('contact_mobile')) &&
										<div className="block-row optional">
											<IconToken icon="mobile" />
											{contact.contact_mobile}
										</div>
										}
										{(contact.contact_fax || 
										required.includes('contact_fax')) &&
										<div className="block-row optional">
											<IconToken icon="fax" />
											{contact.contact_fax}
										</div>
										}
									</div>
								</div>
							</div>
							{props.allowEdit && props.actionContactAddEdit &&
							(!props.className || !props.className.match('horizontal')) &&
								<Fragment>
									<div className="ctl">
										{contact &&
											<IconToken
												icon="user-edit" 
												tooltip="Edit Contact" 
												action={()=>{handleAddEdit('contact', contact)}} />
										}
										{!contact &&
											<IconToken
												icon="user-add" 
												tooltip="New Contact" 
												action={()=>{handleAddEdit('contact', null)}} />	
										}
									</div>
								</Fragment>
							}
						</Fragment>
						}
						{!contact && required &&
							<div className="error" style={{marginBottom: '1em', fontWeight: 'normal'}}>
							{props.allowEdit ?
								<Control {...props}
									className={`ctl-btn flex-full${props.error ? ` error-bg` : ''}`}
									icon="user-add"
									label="Add Contact Information"
									showLabel={true}
									action={()=>{handleAddEdit('contact', null)}} />
							: `Information missing`
							}
							</div>	
						}
					</div>
				</div>
			</div>
		</Fragment>
		
		
		
		// <Fragment>
		// 	<div className={`block contact${props.className ? ` ${props.className}` : ''}`}>
		// 		{contact &&
		// 		<Fragment>
		// 			
		// 			{props.contactHeading &&
		// 				<div className="block-heading">
		// 					{props.contactHeading}
		// 				</div>
		// 			}
		// 			
		// 			<div className="block-row title">
		// 				{contact.contact_name_first && contact.contact_name_last ?
		// 					`${contact.contact_name_first} ${contact.contact_name_last}`
		// 				:
		// 					<div className="error" style={{marginBottom: '1em', fontWeight: 'normal'}}>
		// 					{props.allowEdit ?
		// 						<Control {...props}
		// 							className={`ctl-btn flex-full${props.error ? ` error-bg` : ''}`}
		// 							icon="user-add"
		// 							label="Add Contact Information"
		// 							showLabel={true}
		// 							action={()=>{handleAddEdit('contact', null)}} />
		// 					: `Information missing`
		// 					}
		// 					</div>	
		// 				}
		// 				{contact.contact_default &&
		// 					<IconToken icon="star" tooltip="Default Contact" />
		// 				}
		// 			</div>
		// 			{contact.contact_title &&
		// 				<div className="block-row subtext">
		// 					{contact.contact_title}
		// 				</div>
		// 			}
		// 			<div className="block-row">
		// 				<IconToken icon="email" />
		// 				{contact.contact_email}
		// 			</div>
		// 			{(contact.contact_phone || 
		// 			required.includes('contact_phone')) &&
		// 				<div className={`block-row ${required.includes('contact_phone') ? '' : ' optional'}${!contact.contact_phone ? ' error' : ''}`}>
		// 					<IconToken icon="phone" />
		// 					{contact.contact_phone? contact.contact_phone : 'Missing Phone Number!'}
		// 				</div>
		// 			}
		// 			{(contact.contact_mobile || 
		// 			required.includes('contact_mobile')) &&
		// 			<div className="block-row optional">
		// 				<IconToken icon="mobile" />
		// 				{contact.contact_mobile}
		// 			</div>
		// 			}
		// 			{(contact.contact_fax || 
		// 			required.includes('contact_fax')) &&
		// 			<div className="block-row optional">
		// 				<IconToken icon="fax" />
		// 				{contact.contact_fax}
		// 			</div>
		// 			}
		// 		</Fragment>
		// 		}
		// 		{!contact && required &&
		// 			<div className="error" style={{marginBottom: '1em', fontWeight: 'normal'}}>
		// 			{props.allowEdit ?
		// 				<Control {...props}
		// 					className={`ctl-btn flex-full${props.error ? ` error-bg` : ''}`}
		// 					icon="user-add"
		// 					label="Add Contact Information"
		// 					showLabel={true}
		// 					action={()=>{handleAddEdit('contact', null)}} />
		// 			: `Information missing`
		// 			}
		// 			</div>	
		// 		}
		// 	</div>
		// 	{props.allowEdit && props.actionContactAddEdit &&
		// 	(!props.className || !props.className.match('horizontal')) &&
		// 		<div className="ctl">
		// 			{contact &&
		// 				<IconToken
		// 					icon="user-edit" 
		// 					tooltip="Edit Contact" 
		// 					action={()=>{handleAddEdit('contact', contact)}} />
		// 			}
		// 			{!contact &&
		// 				<IconToken
		// 					icon="user-add" 
		// 					tooltip="New Contact" 
		// 					action={()=>{handleAddEdit('contact', null)}} />	
		// 			}
		// 		</div>
		// 	}
		// </Fragment>
	)
}